import React from 'react'

class TruncatedText extends React.Component {
  render() {
    return (
      <div
        style={{
          maxWidth: this.props.width ?? 250,
          minWidth: this.props.width ?? 250,
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
        title={this.props.children}>
        {this.props.children}
      </div>
    )
  }
}

export default TruncatedText
