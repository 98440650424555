import React from 'react'
import { Table, Modal, Button } from 'react-bootstrap'
import DownloadDocumentButton from 'components/system_wide/download_document_button'
import { ModalRecipientsTable } from './recipients_table'

class AttachmentsTable extends React.PureComponent {
  render() {
    let { attachments, poTrans, documentUrl = '', recipients = [], print_options_enabled = true } = this.props
    return (
      <Table striped bordered hover size="sm">
        <thead style={{ position: 'sticky', top: 0, background: 'white', width: '101%' }}>
          <tr>
            <th className="border">Nome file</th>
            {print_options_enabled !== false && <th className="border">Opzioni di stampa</th>}
            {recipients.length > 0 && <th className="border">Destinatari</th>}
            {documentUrl !== '' && <th className="border">Scarica</th>}
          </tr>
        </thead>
        <tbody style={{ maxHeight: 470, overflow: 'auto' }}>
          {attachments.map((e, idx) => {
            let print_opts = (e?.print_options ?? []).map(p => poTrans?.[p.category]?.[p.value] ?? p.value)
            return (
              <tr key={`r-${e.id}`} style={{ height: '2rem' }}>
                <td>
                  {idx === 0 ? (
                    <i className="fa fa-star me-2" style={{ color: '#bcbcbc' }} title="Allegato principale" />
                  ) : (
                    ''
                  )}
                  {e.name}
                </td>
                {print_options_enabled !== false && <td>{print_opts.join(', ')}</td>}
                {recipients.length > 0 && (
                  <td>
                    <ModalRecipientsTable recipients={recipients} attachment={e} />
                  </td>
                )}
                {documentUrl !== '' && (
                  <td>
                    <DownloadDocumentButton
                      className=""
                      documentUrl={documentUrl + e.id}
                      filename={e.name}
                      variant="primary"
                      iconRight="fa-download"
                    />
                  </td>
                )}
              </tr>
            )
          })}
          {attachments.length === 0 && (
            <tr>
              <td colSpan="7" className="text-center pt-3 w-100">
                Nessun elemento trovato
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )
  }
}

export class ModalAttachmentsTable extends React.PureComponent {
  state = { show: false }

  toggleModal = () => this.setState({ show: !this.state.show })

  render() {
    let { show } = this.state
    let {
      attachments = [],
      selected = [],
      poTrans,
      documentUrl = '',
      recipients = [],
      print_options_enabled = true,
    } = this.props
    let selected_attachments = attachments.filter(a => selected.includes(a.id))
    return (
      <>
        <Modal show={show} size="lg">
          <Modal.Header>
            <h5>Lista allegati</h5>
          </Modal.Header>
          <Modal.Body>
            <AttachmentsTable
              attachments={selected_attachments}
              poTrans={poTrans}
              documentUrl={documentUrl}
              recipients={recipients}
              print_options_enabled={print_options_enabled}
            />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="secondary" onClick={this.toggleModal}>
              <i className={`fa fa-times`} /> Chiudi
              <div />
            </Button>
          </Modal.Footer>
        </Modal>
        <div onClick={this.toggleModal}>
          <i className="fa fa-file-alt text-primary" /> {selected_attachments.length}
        </div>
      </>
    )
  }
}

export default AttachmentsTable
