import React from 'react'
import { Button, Modal, Form } from 'react-bootstrap'

class TrackingModal extends React.Component {
  state = {
    tracking_code: null,
    show_modal: false,
  }

  componentDidMount() {
    this.setState({ tracking_code: this.props.element.tracking_code })
  }

  handleSubmit = () => {
    this.props.submitTrackingcode(this.props.element.id, this.state.tracking_code)
    this.setState({ show_modal: false })
  }
  handleKeyDown = event => {
    if (event.key === 'Enter') {
      this.props.submitTrackingcode(this.props.element.id, this.state.tracking_code)
      this.setState({ show_modal: false })
    }
  }

  handleChange = ({ target }) => {
    let { value } = target
    this.setState({ tracking_code: value })
  }

  toggleModal = () => this.setState({ show_modal: !this.state.show_modal })
  toggleSent = () => {
    this.props.submitSent(this.props.element.id)
    this.setState({ show_modal: false })
  }

  renderButton = () => {
    let { element, disabled } = this.props
    let sentButton = ['paper2', 'paper9', 'paper10'].includes(element.send_typology)

    if (['email', 'pec', 'app'].includes(element.send_typology))
      return (
        <div>
          {element.current_state === 'completed' && <i className="fa fa-check text-success" />}
          {element.current_state === 'discared' && <i className="fa fa-xmark text-warning" />}
          {element.current_state === 'blocked' && <i className="fa fa-xmark text-danger" />}
        </div>
      )

    if (sentButton)
      return (
        <Button onClick={this.toggleSent} size="sm" disabled={disabled}>
          Spedito
        </Button>
      )
    else
      return (
        <Button onClick={this.toggleModal} size="sm" disabled={disabled}>
          Modifica
        </Button>
      )
  }

  render() {
    let { element, disabled } = this.props
    let { tracking_code, show_modal } = this.state

    return (
      <>
        {element.tracking_url ? (
          <div className="d-flex justify-content-between align-items-center">
            <a
              href={element.tracking_url}
              target="_blank"
              rel="noreferrer"
              className="fw-bold"
              style={{ textDecoration: 'none' }}>
              <i className="me-2 fa fa-truck" />
              {element.tracking_code}
            </a>
            <Button onClick={this.toggleModal} size="sm" disabled={disabled}>
              <i className="fa fa-edit" />
            </Button>
          </div>
        ) : (
          this.renderButton()
        )}
        {show_modal === true && (
          <Modal show={show_modal}>
            <Modal.Header className="fw-bold">
              <h5>Modifica tracking code</h5>
            </Modal.Header>
            <Modal.Body>
              <Form onKeyDown={this.handleKeyDown}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Control
                    name="tracking_code"
                    type="text"
                    placeholder="Tracking code"
                    onChange={this.handleChange}
                    value={tracking_code}
                    autoFocus={true}
                  />
                </Form.Group>
                <div className="d-flex justify-content-between">
                  <Button variant="secondary" className="rounded-3 mt-2 mb-2" onClick={this.toggleModal}>
                    Chiudi
                  </Button>
                  <Button
                    variant="primary"
                    className="rounded-3 mt-2 mb-2"
                    disabled={!tracking_code}
                    onClick={this.handleSubmit}>
                    Salva
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        )}
      </>
    )
  }
}

class Tracking extends React.Component {
  static defaultProps = {
    element: {},
  }

  render() {
    let { element, isCondominiaAdmin, submitTrackingcode, submitSent, disabled } = this.props
    if (isCondominiaAdmin) {
      if (element.send_typology === 'email' || element.send_typology === 'pec') {
        return (
          <div>
            {element.current_state === 'completed' && <i className="fa fa-check text-success" />}
            {element.current_state === 'discared' && <i className="fa fa-xmark text-warning" />}
            {element.current_state === 'blocked' && <i className="fa fa-xmark text-danger" />}
          </div>
        )
      }
      return (
        <div>
          {element.tracking_url ? (
            <a
              href={element.tracking_url}
              target="_blank"
              rel="noreferrer"
              className="text-secondary fw-bold"
              style={{ textDecoration: 'none' }}>
              <i className="me-1 fa fa-truck" />
              {element.tracking_code}
            </a>
          ) : (
            <span className="text-secondary">Non ancora tracciabile</span>
          )}
        </div>
      )
    } else
      return (
        <TrackingModal
          element={element}
          submitTrackingcode={submitTrackingcode}
          submitSent={submitSent}
          disabled={disabled}
        />
      )
  }
}

export default Tracking
