import config from 'config'

export const isStateBefore = (current_state, limit_state) => {
  let statuses = config.ASSIGNMENTS_STATUSES
  let state1 = statuses.find(s => s.id === current_state)?.order || 99
  let state2 = statuses.find(s => s.id === limit_state)?.order || 99
  return state1 < state2
}

export const isDev = () => {
  if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    return false
  } else return false
}

// check se sei admin o tecnico
export const isAdmin = (role_id, roles) => {
  let admin_id = Object.keys(roles).find(s => roles[s] === 'admin')
  let technician_id = Object.keys(roles).find(s => roles[s] === 'technician')
  return role_id === admin_id || role_id === technician_id
}
export const isNoTaxDatum = (role_id, roles) => {
  let recapitista_id = Object.keys(roles).find(s => roles[s] === 'delivery_person')
  let subaccount_id = Object.keys(roles).find(s => roles[s] === 'subaccount')
  return role_id === recapitista_id || role_id === subaccount_id
}

// check se sei amministratore di condominio
export const isCondominiaAdmin = (role_id, roles) => {
  let building_administrator_id = Object.keys(roles).find(s => roles[s] === 'building_administrator')
  return role_id === building_administrator_id
}

export const isRecapitista = (role_id, roles) => {
  let recapitista_id = Object.keys(roles).find(s => roles[s] === 'delivery_person')
  return role_id === recapitista_id
}

export const getRoleIdByName = (role_name, roles) => {
  let role_id = Object.keys(roles).find(s => roles[s] === role_name)
  return role_id
}

export const printOptionsLabels = {
  paper_size: 'Formato pagina',
  print_type: 'Tipo di stampa',
  color: 'Colori',
  stapler: 'Pinzatura',
}

export const send_typologies = [
  { id: 'paper', label: 'Posta 1', value: 'paper' },
  { id: 'pec', label: 'PEC', value: 'pec' },
  // { id: 'app', label: 'App', value: 'app' },
  { id: 'email', label: 'Email', value: 'email' },
  { id: 'paper2', label: 'Posta 4', value: 'paper2' },
  { id: 'paper3', label: 'Raccomandata', value: 'paper3' },
  { id: 'paper4', label: 'Raccomandata 1', value: 'paper4' },
  { id: 'paper5', label: 'Raccomandata A/R', value: 'paper5' },
  { id: 'paper6', label: 'Raccomandata 1 A/R', value: 'paper6' },
  { id: 'paper7', label: 'Raccomandata Estera', value: 'paper7' },
  { id: 'paper8', label: 'Raccomandata Estera A/R', value: 'paper8' },
  { id: 'paper9', label: 'Posta internazionale', value: 'paper9' },
  { id: 'paper10', label: 'Posta internazionale Veloce', value: 'paper10' },
]

export const smtp_authentications = [
  { id: 'plain', label: 'Plain', value: 'plain' },
  { id: 'md5', label: 'MD5', value: 'md5' },
  { id: 'login', label: 'Login', value: 'login' },
  { id: 'cram-md5', label: 'CRAM-MD5', value: 'cram-md5' },
]
