import React from 'react'
import { Button } from 'react-bootstrap'
import axios from 'lib/axios'

import 'scss/contracts.css'

export const downloadFile = (documentUrl, filename) => {
  if (!documentUrl || !filename) return null

  axios({
    url: documentUrl,
    method: 'GET',
    responseType: 'blob',
  }).then(response => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data)

    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', filename) //or any other extension
    document.body.appendChild(link)
    link.click()

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  })
}

class DownloadDocumentButton extends React.Component {
  static defaultProps = {
    downloadUrl: null,
    filename: '',
    label: 'Scarica',
  }

  downloadFile = () => {
    let { documentUrl, filename } = this.props
    if (!documentUrl || !filename) return null

    axios({
      url: documentUrl,
      method: 'GET',
      responseType: 'blob',
    }).then(response => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', filename) //or any other extension
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }
  render() {
    return (
      <Button
        disabled={this.props.disabled}
        className={`ms-2 ${this.props.className}`}
        onClick={this.downloadFile}
        size={this.props.size ?? 'sm'}
        variant={this.props.variant ?? 'secondary'}>
        {this.props.iconLeft && <i className={`me-2 fa ${this.props.iconLeft}`} />}
        {this.props.label}
        {this.props.iconRight && <i className={`ms-2 fa ${this.props.iconRight}`} />}
      </Button>
    )
  }
}
export default DownloadDocumentButton
