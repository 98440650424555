import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import i18next from 'i18next'

import config from 'config'
import { getRoleIdByName, isStateBefore } from 'lib/utils'
import { error, success } from 'components/system_wide/notification'
import { redirectLocation } from 'store/common'
import { updateErrors as updateUserErrors } from 'store/users'
import { toggleShowSignature } from 'store/assignments/services'

const { t } = i18next

export const initialState = {
  assignments: [],
  assignments_ready: [],
  admin_users: [],
  errors: [],
  loading: false,
  show_modal: false,
  current_assignment: { dossiers: [], condominia: [], requirements: {} },
  total: 0,
  filtered_services: [],
  filtered_statuses: [],
  filtered_roles: [],
}

export const getAdminUsers = createAsyncThunk('assignments/listAdminUser', async (role_id, thunkApi) => {
  try {
    let url = `${config.SERVER_API_URL}/v1/roles/${role_id}/users?filter[is_deactivated]=false`
    let response = await axios({
      url,
      method: 'GET',
    })
    let users = response.data.data.map(u => {
      let { name, surname } = u.attributes
      return { value: u.id, label: `${name} ${surname}` }
    })
    return { users }
  } catch (err) {
    console.log(err)
    error(t('store.users.get_users_error', 'Impossibile recuperare gli utenti'))
    return thunkApi.rejectWithValue()
  }
})

export const getAssignments = createAsyncThunk('assignments/list', async (params, thunkApi) => {
  try {
    let { offset, limit, sortData, filter } = params
    let { sort_column, sort_direction } = sortData
    let state = thunkApi.getState().assignments.data

    let pages = state.total / limit
    let current_page = (offset * pages) / state.total + 1 || 1

    let url = `${config.SERVER_API_URL}/v1/assignments?page=${current_page}&per=${limit}`
    if (filter) url = url + `&q=${encodeURIComponent(filter)}`
    if (sort_column !== '') {
      sort_column = ['name', 'surname'].includes(sort_column) ? `users.${sort_column}` : sort_column
      url = url + `&sort=${sort_direction === 'ASC' ? '' : '-'}${sort_column}`
    }
    if (state.filtered_services.length > 0) url = url + `&filter[with_services]=${state.filtered_services.join(',')}`
    if (state.filtered_statuses.length > 0)
      url = url + `&filter[with_current_state]=${state.filtered_statuses.join(',')},ready`
    let response = await axios({
      url,
      method: 'get',
    })
    let { data: assignments_data } = response.data
    let assignments_ready = assignments_data
      .filter(as => as.attributes.current_state === 'ready')
      .map(as => {
        let { id, attributes, relationships } = as
        let { current_state, order, user } = attributes
        let { name, surname, role_id } = user
        let services = relationships?.services?.data.map(s => s.id) || []
        return { id, name, surname, role_id, current_state, order, services }
      })
    let assignments = assignments_data
      .filter(as => as.attributes.current_state !== 'ready')
      .map(as => {
        let { id, attributes, relationships } = as
        let { current_state, order, user } = attributes
        let { name, surname, role_id } = user
        let services = relationships?.services?.data.map(s => s.id) || []
        return { id, name, surname, role_id, current_state, order, services }
      })
    let { count: total } = response.data.links

    return { assignments, assignments_ready, total: total - assignments_ready.length, reset: offset === 0 }
  } catch (err) {
    console.log(err)
    error(t('store.users.get_assignments_error', 'Impossibile recuperare gli incarichi'))
    return thunkApi.rejectWithValue()
  }
})

export const createAssignment = createAsyncThunk('assignments/create', async ({ user_id, user_data }, thunkApi) => {
  try {
    if (user_id === '') {
      let { roles } = thunkApi.getState().usersData
      let { id, role_id, ...user } = user_data
      let build_administrator_role_id = getRoleIdByName(role_id, roles)
      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/users`,
        method: 'POST',
        data: { ...user, role_id: build_administrator_role_id },
      })
      user_id = response.data.data.id
    }
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/users/${user_id}/assignments`,
      method: 'POST',
    })
    let assignment_id = response.data.data.id
    thunkApi.dispatch(setCurrentAssignment(assignment_id))
    thunkApi.dispatch(redirectLocation(`/assignments/${assignment_id}/services`))
    thunkApi.dispatch(toggleShowModal())
    success(t('store.users.create_assignment_success', 'Pratica avviata con successo'))
    return {}
  } catch (err) {
    console.log(err)
    let {
      data: { errors: from_server },
    } = err.response
    let errors = { from_server }
    thunkApi.dispatch(updateUserErrors({ errors }))
    error(t('store.users.create_assignment_error', 'Impossibile avviare la pratica'))
    return thunkApi.rejectWithValue()
  }
})

export const getRequirements = createAsyncThunk(
  'assignments/get_requirements',
  async ({ assignment_id, dossiers }, thunkApi) => {
    try {
      let requirements = {}
      for (let el of dossiers) {
        let { data: resp } = await axios({
          url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/dossiers/${el.id}/requirements`,
          method: 'GET',
        })
        requirements = { ...requirements, [el.id]: [] }
        if (resp.included) {
          for (let rq of resp.included) {
            if (rq.type === 'service_requirements') {
              let feature_id = rq.relationships.feature.data.id
              let feature_attributes = resp.included.filter(f => f.type === 'features').find(f => f.id === feature_id)
              requirements[el.id].push({ id: rq.id, ...rq.attributes, ...feature_attributes?.attributes })
            }
          }
        }
      }
      return requirements
    } catch (err) {
      console.log(err)
      let {
        data: { errors: from_server },
      } = err.response
      let errors = { from_server }
      thunkApi.dispatch(updateUserErrors({ errors }))
      error(t('store.users.create_assignment_error', 'Impossibile recuperare le informazioni riguardanti i requisiti'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const setCurrentAssignment = createAsyncThunk('assignments/set_assignment', async (assignment_id, thunkApi) => {
  try {
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}`,
      method: 'GET',
    })
    let {
      included,
      data: { attributes },
    } = response.data
    let { id, current_state, order, user } = attributes
    let dossiers = []
    let services = []
    if (included?.length) {
      dossiers = included
        .filter(d => d.type === 'dossiers')
        .map(d => {
          let { assignment, ...attributes } = d.attributes
          let service = d.attributes.service.id
          services.push(service)
          return { id: d.id, ...attributes }
        })
    }

    let prices = {}
    dossiers.forEach(async d => {
      let { data: resp_price } = await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/dossiers/${d.id}/condominia`,
        method: 'GET',
      })
      resp_price.data.map(dsp => {
        let {
          attributes: { price },
          relationships: { condominium },
        } = dsp
        prices = { ...prices, [condominium.data.id]: { ...(prices[condominium.data.id] ?? {}), [d.id]: price } }
      })
    })

    let condominia = []
    if (!isStateBefore(current_state, 'configuration')) {
      let { data: resp_condominia } = await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/condominia?sort=name`,
        method: 'GET',
      })
      if (resp_condominia?.data?.length) {
        condominia = resp_condominia.data.map(c => {
          let condominium_dossiers = c.relationships.dossiers.data.reduce((acc, cd) => {
            let ds = dossiers.find(d => d.id === cd.id)
            return { ...acc, [ds.service_id]: ds.service_options[0] }
          }, {})
          return { id: c.id, ...c.attributes, services_options: condominium_dossiers }
        })
      }
    }
    let otp_verification_id = ''
    let verificable_id = ''
    let verified = false
    if (attributes.current_state === 'otp') {
      otp_verification_id = attributes.otp_verification.id
      verificable_id = attributes.otp_verification.verificable_id
      verified = attributes.otp_verification.verified
    }

    thunkApi.dispatch(getRequirements({ assignment_id, dossiers }))

    return {
      id: assignment_id,
      current_state,
      order,
      user,
      dossiers,
      services,
      condominia,
      prices,
      otp_verification_id,
      verificable_id,
      verified,
    }
  } catch (err) {
    console.log(err)
    error(t('store.users.get_assignment_error', "Impossibile recuperare l'incarico"))
    return thunkApi.rejectWithValue()
  }
})

export const signAssignment = createAsyncThunk(
  'assignments/signAssignment',
  async ({ assignment_id, signature }, thunkApi) => {
    try {
      let response = await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}`,
        method: 'PUT',
      })
      let order_id = response.data.data.attributes.order.id

      let formData = new FormData()
      const res = await fetch(signature)
      const blob = await res.blob()
      const file = new File([blob], 'signature.png', { type: 'image/png' })
      formData.append('_jsonapi[sign]', file)
      formData.append('accepted', true)

      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}/orders/${order_id}`,
        method: 'PUT',
        data: formData,
      })
      thunkApi.dispatch(setLoading(true))
      thunkApi.dispatch(resetCurrentAssignment())
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
      thunkApi.dispatch(toggleShowSignature())
      success(t('store.assignments.sign_assignment_success', 'Firma inviata con successo'))
    } catch (err) {
      console.log(err)
      error(t('store.assignments.sing_assignment_error', 'Impossibile inviare la firma'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const changeState = createAsyncThunk(
  'assignments/changeState',
  async ({ assignment_id, send_otp = false }, thunkApi) => {
    try {
      await axios({
        url: `${config.SERVER_API_URL}/v1/assignments/${assignment_id}`,
        method: 'PUT',
      })
      thunkApi.dispatch(setCurrentAssignment(assignment_id))
    } catch (err) {
      console.log(err)
      error(t('store.assignments.change_state_assignment_error', 'Impossibile aggiornare lo stato'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const goToState = createAsyncThunk(
  'assignments/goToState',
  async ({ assignment_id, current_state }, thunkApi) => {
    try {
      // Inserire salto degli step
      switch (current_state) {
        case 'pending':
        case 'otp':
          thunkApi.dispatch(redirectLocation(`/assignments/${assignment_id}/services`))
          break
        case 'acceptance':
          thunkApi.dispatch(redirectLocation(`/assignments/${assignment_id}`))
          break
        case 'configuration':
        case 'confirmation':
        case 'to_fix':
          thunkApi.dispatch(redirectLocation(`/assignments/${assignment_id}/condominia`))
          break
        case 'price_definition':
          thunkApi.dispatch(redirectLocation(`/assignments/${assignment_id}/prices`))
          break
        case 'ready':
          thunkApi.dispatch(redirectLocation(`/assignments/${assignment_id}/dashboard`))
          break
        default:
      }
    } catch (err) {
      console.log(err)
      error(t('store.assignments.go_to_state_assignment_error', 'Impossibile riprendere'))
      return thunkApi.rejectWithValue()
    }
  }
)

export const assignmentsSlice = createSlice({
  name: 'assignments',
  initialState,

  reducers: {
    toggleShowModal: state => {
      state.show_modal = !state.show_modal
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    resetCurrentAssignment: state => {
      state.current_assignment = { dossiers: [], condominia: [] }
      state.errors = []
    },
    updateAssignmentErrors: (state, action) => {
      state.errors = action.payload.errors
    },
    updateErrors: (state, action) => {
      let { errors } = action.payload
      state.errors = errors
    },
    filterServices: (state, action) => {
      state.filtered_services = action.payload
    },
    filterStatuses: (state, action) => {
      state.filtered_statuses = action.payload
    },
    filterRoles: (state, action) => {
      state.filtered_roles = action.payload
    },
  },
  extraReducers: {
    [getAdminUsers.fulfilled]: (state, action) => {
      if (!action?.payload) return null
      let { users } = action.payload
      state.admin_users = users
    },
    [getAdminUsers.rejected]: state => {
      state.total = initialState.total
      state.admin_users = initialState.admin_users
    },
    [getAssignments.fulfilled]: (state, action) => {
      if (!action?.payload) return
      let { total, assignments, assignments_ready } = action.payload
      state.total = total
      state.assignments = action.payload.reset ? [...assignments] : [...state.assignments, ...assignments]
      state.assignments_ready = action.payload.reset
        ? [...assignments_ready]
        : [...state.assignments_ready, ...assignments_ready]
    },
    [getAssignments.rejected]: state => {
      state.total = initialState.total
      state.assignments = initialState.assignments
    },
    [setCurrentAssignment.fulfilled]: (state, action) => {
      let { requirements } = state.current_assignment
      state.current_assignment = action.payload
      state.current_assignment.requirements = requirements
      state.loading = false
    },
    [setCurrentAssignment.rejected]: (state, action) => {
      state.current_assignment = { dossiers: [], condominia: [], requirements: {} }
      state.loading = false
    },
    [getRequirements.fulfilled]: (state, action) => {
      state.current_assignment.requirements = action.payload
      state.loading = false
    },
    [getRequirements.rejected]: (state, action) => {
      state.current_assignment.requirements = {}
      state.loading = false
    },
  },
})

export const {
  toggleShowModal,
  resetCurrentAssignment,
  setLoading,
  updateAssignmentErrors,
  updateErrors,
  filterServices,
  filterStatuses,
  filterRoles,
} = assignmentsSlice.actions
export default assignmentsSlice.reducer
