import { configureStore, combineReducers } from '@reduxjs/toolkit'

import commonReducer from './common'
import translationsReducer from './translations'
import documentsReducer from './documents'
import loginReducer from './login'
import userInfoReducer from './userinfo'
import usersReducer from './users'
import assignmentsReducer from './assignments'
import condominiaReducer from './condominia'
import contractsReducer from './contracts'
import transfersReducer from './transfers'
import mailingReducer from './mailing'
import servicesReducer from './assignments/services'
import assignmentCondominiaReducer from './assignments/condominia'

const addGlobalResetEnhancer = createStore => (reducer, initialState, enhancer) => {
  const resettableReducer = (state, action) => {
    if (action.type === 'RESET_STORE') {
      return reducer(undefined, action)
    } else {
      return reducer(state, action)
    }
  }
  return createStore(resettableReducer, initialState, enhancer)
}

export function makeStore() {
  return configureStore({
    reducer: {
      common: commonReducer,
      documents: documentsReducer,
      translations: translationsReducer,
      loginData: loginReducer,
      userInfo: userInfoReducer,
      usersData: usersReducer,
      condominia: condominiaReducer,
      contracts: contractsReducer,
      transfers: transfersReducer,
      mailing: mailingReducer,
      assignments: combineReducers({
        data: assignmentsReducer,
        services: servicesReducer,
        condominia: assignmentCondominiaReducer,
      }),
    },
    enhancers: [addGlobalResetEnhancer],
    // middleware: getDefaultMiddleware =>
    //   getDefaultMiddleware({
    //     serializableCheck: false,
    //   }),
  })
}

const store = makeStore()
export default store

export const resetStore = () => store.dispatch({ type: 'RESET_STORE' })
