import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { Button, Form, Dropdown, OverlayTrigger, Tooltip, Spinner, ProgressBar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import config from 'config'

import { deleteMailer, getMailing, resetCurrentMailer, acceptMailSender, confirmMailer } from 'store/mailing'
import { getSingleCondominium, requestCondominiumContracts } from 'store/condominia'
import { getSingleUser } from 'store/users'
import ResponsiveTable from 'components/system_wide/responsive_table'
import ActionsDropdownSplitted from 'components/system_wide/actions_dropdown_splitted'
import SearchBox from 'components/system_wide/search_box'
import { renderExpireAt } from 'components/system_wide/utils'
import { isAdmin, isCondominiaAdmin } from 'lib/utils'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import CostCenterSelector from './components/cost_center_selector'
import { ModalAttachmentsTable } from './attachments_table'
import ShowCondominium from 'components/condominia/show_condominium'
import ShowUserModal from 'components/users/show_user'

const { t } = i18next

const mstp = state => {
  let { mailing, total, current_mailer } = state.mailing
  let { strings: trans } = state.translations
  let { role_id, id: my_user_id, name, surname } = state.userInfo
  let { roles } = state.usersData
  let tServices = trans?.tables?.services ?? {}
  let tRoles = trans?.tables?.roles ?? {}
  return {
    total,
    mailing,
    trans,
    isAdmin: isAdmin(role_id, roles),
    my_user_id,
    current_mailer,
    isCondominiaAdmin: isCondominiaAdmin(role_id, roles),
    name,
    surname,
    tServices,
    roles,
    tRoles,
  }
}

class MailingList extends React.Component {
  static defaultProps = {
    mailing: [],
    getData: () => {},
    columns: [],
    filter: null,
  }

  state = {
    show_modal_new: false,
    show_modal_select_targets: false,
    show_modal_print_options: false,
    show_modal_summary: false,
    show_modal_condominium: false,
    show_modal_user: false,
    condominium: {},
    user: {},
    filter: '',
    filterTracking: '',
    filterPayerType: '',
    filterPayer: '',
    ready: false,
    selected: {},
    activeTab: 'attivi',

    type_selected: 'single',
    selected_mailing: [],
    loading: false,
    progress: 0,
  }

  tableRef = null
  bulk_actions = {
    to_review: t('actions_dropdown.to_review', 'Da revisionare'),
  }

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
  }

  componentDidMount = async () => {
    // this.props.dispatch(getValidators())
    this.props.dispatch(resetCurrentMailer())
    this.setState({ ready: true }, () => this.tableRef.current.refreshData())
  }

  selectCheckboxRender = ({ rowData }) => {
    let { selected_mailing } = this.state

    return (
      <Form.Check
        type="checkbox"
        className="py-1"
        value={rowData.id}
        checked={selected_mailing.includes(rowData.id.toString())}
        onChange={this.toggleMailingEdit}
      />
    )
  }

  handleSelection = () => {
    this.setState(
      {
        type_selected: this.state.type_selected === 'single' ? 'multiple' : 'single',
        selected_mailing: this.state.type_selected === 'single' ? [] : this.state.selected_mailing,
      },
      () => {
        this.tableRef?.current.refreshData()
      }
    )
  }

  toggleAllMailing = () => {
    let { selected_mailing, activeTab } = this.state
    let { mailing } = this.props
    let active_mailing = activeTab === 'attivi' ? mailing : []
    let all_selected = active_mailing.every(u => selected_mailing.includes(u.id.toString()))
    let current_mailing = []

    if (all_selected) {
      current_mailing = selected_mailing.filter(su => !active_mailing.find(u => u.id.toString() === su))
    } else {
      current_mailing = new Set([...selected_mailing, ...active_mailing.map(e => e.id.toString())])
    }
    this.setState({ selected_mailing: [...current_mailing] })
  }

  renderCheckbox = () => {
    let { selected_mailing, activeTab, type_selected } = this.state
    let { mailing } = this.props
    if (type_selected === 'single') return ''
    let active_mailing = activeTab === 'attivi' ? mailing : ''
    let all_selected = active_mailing.every(u => selected_mailing.includes(u.id.toString()))
    return (
      <Button onClick={this.toggleAllMailing} variant="secondary" className="no-padding-button">
        <i className={`far ${all_selected ? 'fa-check-square' : 'fa-square'}`} style={{ fontSize: '1.5rem' }}></i>
      </Button>
    )
  }

  deleteMailer = async id => {
    showConfirmDialog(
      'Elimina postalizzazione',
      'Sei sicuro di voler annullare la postalizzazione in corso? Tutti i dati inseriti andranno persi!',
      async () => {
        if (id) await this.props.dispatch(deleteMailer(id))
        this.tableRef.current.refreshData()
      },
      () => {},
      'danger',
      'Annulla postalizzazione'
    )
  }

  acceptMailSender = async id => {
    await this.props.dispatch(acceptMailSender(id))
    this.tableRef.current.refreshData()
  }
  confirmMailer = async id => {
    await this.props.dispatch(confirmMailer(id))
    this.tableRef.current.refreshData()
  }

  actions = [
    ...(!this.props.isCondominiaAdmin
      ? [
          {
            icon: 'fa-thumbs-up',
            text: t('actions_dropdown.accept_mailing', 'Accetta'),
            disabled: rowData => rowData.current_state !== 'confirmed',
            onClick: ({ id }) => {
              this.acceptMailSender({ id })
            },
          },
          {
            icon: 'fa-flag',
            text: t('actions_dropdown.confirm_mailing', 'Termina'),
            disabled: rowData => rowData.current_state !== 'working',
            onClick: ({ id }) => {
              this.confirmMailer({ id })
            },
          },
        ]
      : []),
    {
      icon: 'fa-trash',
      text: t('actions_dropdown.delete_mailing', 'Cancella'),
      disabled: rowData => rowData.current_state !== 'open',
      onClick: ({ id }) => {
        this.deleteMailer(id)
      },
    },
  ]

  openMailSender = async rowData => {
    this.props.navigate(`/mailing/edit/${rowData.id}`)
  }
  openFinalize = async rowData => {
    this.props.navigate(`/mailing/finalize/${rowData.id}`)
  }

  actionsRenderer = ({ rowData }) => {
    return (
      <ActionsDropdownSplitted
        rowData={rowData}
        actions={this.actions}
        onClickMaster={() =>
          rowData.current_state === 'open' ? this.openMailSender(rowData) : this.openFinalize(rowData)
        }
      />
    )
  }

  renderState = ({ rowData: { current_state } }) => {
    let color = 'text-primary'
    if (current_state === 'confirmed') color = 'text-success'
    if (current_state === 'rejected' || current_state === 'cancelled') color = 'text-danger'
    if (current_state === 'completed') color = 'black'
    let { mailing: trTrans } = this.props.trans?.state_machines ?? []
    let label = trTrans?.[current_state] ?? current_state
    return <div className={`${color} fw-bold`}>{label.toUpperCase()}</div>
  }

  renderDate = ({ rowData: { created_at } }) => {
    return <div>{renderExpireAt(created_at)}</div>
  }
  renderAttachments = ({ rowData }) => {
    let { current_mailer, trans } = this.props
    let { print_options: poTrans } = trans?.tables || {}

    return (
      <ModalAttachmentsTable
        attachments={rowData.attachments}
        selected={rowData.attachments.map(a => a.id)}
        poTrans={poTrans}
        documentUrl={`${config.SERVER_API_URL}/v1/mail_senders/${current_mailer.id}/attachments/`}
        print_options_enabled={false}
      />
    )
  }
  onShowCondominium = id => async () => {
    let condominium = await getSingleCondominium(id)
    condominium.contracts = await requestCondominiumContracts(id)
    this.setState({ condominium, show_modal_condominium: true })
  }
  onCloseCondominium = () => this.setState({ condominium: {}, show_modal_condominium: false })

  onShowUser = id => async () => {
    let user = await getSingleUser(id)
    this.setState({ user, show_modal_user: true })
  }
  onCloseUser = () => this.setState({ user: {}, show_modal_user: false })

  renderCostCenter = ({ rowData }) => {
    let name = rowData?.payer?.name
    if (rowData.payer_type === 'User') {
      name = rowData.payer?.tax_datum?.business_name ?? `${rowData.payer.name} ${rowData.payer.surname}`
      return (
        <div className="text-primary fw-bold" style={{ cursor: 'pointer' }} onClick={this.onShowUser(rowData.payer_id)}>
          {name}
        </div>
      )
    } else {
      return (
        <div
          className="text-primary fw-bold"
          style={{ cursor: 'pointer' }}
          onClick={this.onShowCondominium(rowData.payer_id)}>
          {name}
        </div>
      )
    }
  }
  getColumn = () => {
    let { isCondominiaAdmin } = this.props
    return [
      {
        title: this.renderCheckbox(),
        className: 'd-lg-block text-center',
        style: { width: '9%', minWidth: '8rem' },
        data: rowData => {
          return this.state.type_selected === 'single'
            ? this.actionsRenderer(rowData)
            : this.selectCheckboxRender(rowData)
        },
      },
      ...(!isCondominiaAdmin
        ? [
            {
              title: t('mailing.id', 'ID'),
              className: 'd-lg-block flex-fill',
              style: { width: '1%', minWidth: '4rem' },
              sortable: true,
              data: 'id',
            },
          ]
        : [{}]),
      {
        title: t('mailing.list.current_state', 'Stato'),
        className: 'd-lg-block flex-fill',
        style: { width: '5%' },
        sortable: true,
        data: this.renderState,
      },
      ...(!isCondominiaAdmin
        ? [
            {
              title: t('mailing.list.user', 'Amministratore'),
              className: 'd-lg-block flex-fill',
              style: { width: '20%', minWidth: '3rem' },
              data: ({ rowData }) => {
                let name = rowData?.user?.tax_datum?.business_name ?? `${rowData?.user?.surname} ${rowData?.user?.name}`
                return name
              },
            },
          ]
        : []),
      {
        title: t('mailing.list.title', 'Oggetto'),
        className: 'd-lg-block flex-fill',
        style: { width: '20%', minWidth: '3rem' },
        sortable: true,
        data: 'title',
      },
      {
        title: t('mailing.list.attachments', 'Allegati'),
        className: 'd-lg-block flex-fill',
        style: { width: '3rem' },
        data: this.renderAttachments,
      },
      {
        title: t('mailing.list.payer', 'Centro di costo'),
        className: 'd-lg-block flex-fill',
        style: { width: '20%' },
        data: this.renderCostCenter,
      },
    ]
  }

  getData = async (index = 0, chunkSize, sortData) => {
    let { filter, filterPayer, filterTracking, filterPayerType } = this.state
    await this.props.dispatch(
      getMailing({ offset: index, limit: chunkSize, sortData, filter, filterTracking, filterPayer, filterPayerType })
    )
  }

  onFilterChange = filter => {
    this.setState({ filter }, () => {
      this.tableRef.current.refreshData()
    })
  }
  onFilterChangeTraking = filterTracking => {
    this.setState({ filterTracking }, () => {
      this.tableRef.current.refreshData()
    })
  }
  onFilterChangePayerType = (value, payer_id) => {
    if (value) {
      this.setState({ filterPayerType: value.id, filterPayer: payer_id ?? '' }, () => {
        if (payer_id) this.tableRef.current.refreshData()
      })
    } else {
      this.setState({ filterPayerType: '', filterPayer: payer_id }, () => {
        this.tableRef.current.refreshData()
      })
    }
  }
  onFilterChangePayer = value => {
    this.setState({ filterPayer: value?.id ?? '' }, () => {
      this.tableRef.current.refreshData()
    })
  }

  onCloseModal = modal => () => this.setState({ [`show_modal_${modal}`]: false })
  handleSelectTypeTransfer = value => () => {
    this.setState({ activeTab: value, selected_mailing: [] })
  }

  render() {
    let { mailing, total, isCondominiaAdmin, name, surname, my_user_id, tServices, roles, tRoles } = this.props
    let {
      type_selected,
      selected_mailing,
      activeTab,
      loading,
      progress,
      filterPayerType,
      filterPayer,
      condominium,
      user,
      show_modal_condominium,
      show_modal_user,
    } = this.state
    if (!mailing || this.state.ready === false) return null
    let columns = this.getColumn()

    return (
      <>
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3 pe-lg-3 pt-2 zi-1 vh-94">
          <div className="d-flex flex-column flex-fill">
            <div className="container-fluid px-0">
              <div className="d-flex flex-fill">
                <div className="d-flex flex-fill flex-column flex-wrap">
                  <div className="d-flex gap-2 mb-2 justify-content-between">
                    <div>
                      <Link to={'/mailing/edit/0'}>
                        <Button variant="primary" className="float-md-end">
                          <i className={`fa fa-plus`} /> Nuova postalizzazione
                        </Button>
                      </Link>
                    </div>
                    {!isCondominiaAdmin && <div />}
                    <div className="d-flex flex-wrap">
                      <div style={{ width: '33rem' }}>
                        <CostCenterSelector
                          payer_type={filterPayerType}
                          payer_id={filterPayer}
                          onChangePayerType={this.onFilterChangePayerType}
                          onChangePayer={this.onFilterChangePayer}
                          isAdmin={this.props.isAdmin}
                          user_id={my_user_id}
                          userName={name}
                          userSurname={surname}
                          errors={{}}
                          updateErrors={() => {}}
                        />
                      </div>
                      <div className="d-flex">
                        <SearchBox
                          placeholder="Cerca tracking code"
                          className="float-md-end me-2"
                          value={this.state.filterTracking}
                          onChange={this.onFilterChangeTraking}
                          disabled={this.props.show_form}
                        />
                        <SearchBox
                          className="float-md-end"
                          value={this.state.filter}
                          onChange={this.onFilterChange}
                          disabled={this.props.show_form}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-stretch">
                    {this.props.isAdmin && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-multiselect`}>
                            <Trans i18nKey="mailing.enable_multiselect_label">Abilita/Disabilita Selezione</Trans>
                          </Tooltip>
                        }>
                        <Button
                          onClick={this.handleSelection}
                          className="rounded-0 no-shadow-button"
                          variant="secondary">
                          <i className="fa-solid fa-list-check me-1"></i>
                        </Button>
                      </OverlayTrigger>
                    )}
                    {type_selected === 'multiple' && (
                      <Dropdown className="me-2 action-menu-dropdown">
                        <Dropdown.Toggle
                          variant="light"
                          id="multi-selected-menu"
                          className="rounded-0 action-menu-dropdown">
                          <Trans i18nKey="mailing.bulk_action_label">Azioni massive</Trans>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {Object.keys(this.bulk_actions).map(a => {
                            return (
                              <Dropdown.Item key={a} disabled={selected_mailing.length === 0} onClick={a => {}}>
                                {this.bulk_actions[a]}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    <button
                      style={{ marginLeft: '0.1rem' }}
                      onClick={this.handleSelectTypeTransfer('attivi')}
                      className={`btn btn-outline-primary btn-tab ${activeTab === 'attivi' ? 'btn-tab-active' : ''}`}>
                      Eventi registrati
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex" style={{ minHeight: '83vh' }}>
              <ResponsiveTable
                ref={this.tableRef}
                className="flex-fill border"
                headerClasses="bg-secondary"
                menuWidth="70rem"
                data={mailing}
                getData={this.getData}
                totalRecords={total}
                columns={columns}
                filter={this.state.filter}
              />
            </div>
          </div>
        </div>
        {loading === true && (
          <div
            className="d-flex w-100 vh-94 justify-content-center"
            style={{ position: 'absolute', backgroundColor: '#cfcfcf90', zIndex: '99999' }}>
            <div className="w-100 m-auto text-center">
              <div className="loader-box">
                <Spinner animation="border" role="status"></Spinner>
                <div>Loading...</div>
                <div className="m-auto" style={{ width: '10rem' }}>
                  <ProgressBar now={progress} label={`${progress}%`} />
                </div>
              </div>
            </div>
          </div>
        )}
        {show_modal_condominium && (
          <ShowCondominium condominium={condominium} onCloseModal={this.onCloseCondominium} tServices={tServices} />
        )}
        {show_modal_user && (
          <ShowUserModal
            user={user}
            show={show_modal_user}
            onCloseModal={this.onCloseUser}
            show_contract={false}
            roles={roles}
            tRoles={tRoles}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(MailingList)
