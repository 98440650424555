import React from 'react'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { connect } from 'react-redux'
import { Button, Table, Accordion } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  getMailer,
  getMailerAttachments,
  getRecipients,
  getPrintOptions,
  deleteMailer,
  confirmMailer,
  updateRecipient,
  updateSent,
  acceptMailSender,
} from 'store/mailing'
import { error, success } from 'components/system_wide/notification'
import withJWTSession from 'enhancers/jwt_session'
import config from 'config'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import DownloadDocumentButton from 'components/system_wide/download_document_button'
import AttachmentsTable, { ModalAttachmentsTable } from './attachments_table'
import { isAdmin, isCondominiaAdmin, send_typologies } from 'lib/utils'
import Tracking from './components/tracking'
import PriceList from './price_list'
import Breadcrumbs from './components/breadcrumbs'

const { t } = i18next

const mstp = state => {
  let { administrators } = state.condominia
  let { condominia, users, validators, errors, current_mailer, recipients, print_options } = state.mailing
  let { role_id, id: my_user_id } = state.userInfo
  let { roles } = state.usersData
  let { strings: trans } = state.translations
  return {
    administrators,
    condominia,
    users,
    my_user_id,
    validators,
    errors,
    isAdmin: isAdmin(role_id, roles),
    isCondominiaAdmin: isCondominiaAdmin(role_id, roles),
    current_mailer,
    trans,
    recipients,
    print_options,
  }
}

class Finalize extends React.Component {
  static defaultProps = {
    elements: [],
    attachments: [],
    recipients: [],
  }
  state = {
    selectedFile: '',
    selectedFileLabel: '',
    selected_options: {},
    selected_typology: '',
  }

  async componentDidMount() {
    if (this.props.router.params.mailer_id) {
      await this.props.dispatch(getMailer(this.props.router.params.mailer_id))
      await this.props.dispatch(getMailerAttachments(this.props.router.params.mailer_id))
      await this.props.dispatch(getRecipients(this.props.router.params.mailer_id))
    }
    if (this.props.print_options.length === 0) {
      await this.props.dispatch(getPrintOptions())
    }
    this.setState({
      selected_options: {
        ...Object.keys(this.props.print_options).reduce(
          (acc, o) => ({ ...acc, [o]: this.props.print_options[o].find(op => op.default === true)?.id }),
          {}
        ),
      },
    })
  }
  handleDelete = async () => {
    let { current_mailer } = this.props
    showConfirmDialog(
      'Elimina postalizzazione',
      'Sei sicuro di voler annullare la postalizzazione in corso? Tutti i dati inseriti andranno persi!',
      async () => {
        if (current_mailer.id) await this.props.dispatch(deleteMailer(current_mailer.id))
        this.props.navigate('/mailing')
      },
      () => {},
      'danger',
      'Annulla postalizzazione'
    )
  }
  onConfirm = async () => {
    showConfirmDialog(
      'Confermi di voler finalizzare la lavorazione?',
      'Confermando non sarà più possibile modificare la lavorazione e gli invii digitali (Email/ PEC) saranno immediatamente evasi.',
      async () => {
        try {
          let { current_mailer } = this.props
          let result = await this.props.dispatch(confirmMailer({ id: current_mailer.id }))
          if (!result?.error) this.props.router.navigate('/mailing')
        } catch (err) {
          if (err?.response?.data?.errors?.title) error(err.response.data.errors.title, 4000)
          if (err?.response?.data?.errors?.[0]) error(err.response.data.errors[0].detail, 4000)
          error(t('store.mailing.new_mailing_ko', 'Impossibile inserire il postalizzazione'))
        }
      },
      () => {},
      'success',
      'Conferma'
    )
  }

  acceptMailSender = async () => {
    await this.props.dispatch(acceptMailSender({ id: this.props.router.params.mailer_id }))
    await this.props.dispatch(getMailer(this.props.router.params.mailer_id))
  }

  generateListAttachments = () => {
    try {
      let { recipients, current_mailer } = this.props
      let attachments = current_mailer?.attachments ?? []
      let ar_map = {} //attachment_recipient_map

      if (recipients.length > 0) {
        for (const r of recipients) {
          ar_map[r.id] = []
        }
        for (const a of attachments) {
          let rps = a?.attachment_options?.[0]?.recipient_ids ?? []
          if (rps.length > 0) {
            for (const r of rps) {
              ar_map[r] = [...ar_map?.[r], a?.id]
            }
          } else {
            for (const r of recipients) {
              ar_map[r.id] = [...ar_map?.[r.id], a.id]
            }
          }
        }
      }
      return ar_map
    } catch (err) {
      return {}
    }
  }

  submitTrackingcode = async (id, tracking_code) => {
    let recipient = { id, tracking_code }
    let { current_mailer, my_user_id } = this.props
    await this.props.dispatch(updateRecipient({ user_id: my_user_id, mailer_id: current_mailer.id, recipient }))
  }
  submitSent = async id => {
    let { current_mailer, my_user_id } = this.props
    await this.props.dispatch(updateSent({ user_id: my_user_id, mailer_id: current_mailer.id, recipient: id }))
  }

  render() {
    let { current_mailer, recipients, trans, isCondominiaAdmin } = this.props
    let attachments = current_mailer?.attachments ?? []
    let { print_options: poTrans } = trans?.tables || {}

    let allDisabled =
      current_mailer?.current_state === 'completed' && typeof current_mailer.current_state !== 'undefined'
    let csvDisabled = current_mailer?.current_state === 'open' || current_mailer?.current_state === 'delegated'

    let recipients_map = send_typologies.reduce((acc, el) => {
      acc[el.id] = recipients.filter(r => r.send_typology === el.id)
      return acc
    }, {})
    let ar_map = this.generateListAttachments()

    return (
      <div className="d-flex flex-column w-100 overflow-auto m-2 ms-3 me-3 z-1">
        <div className="d-flex flex-wrap gap-2 mb-2 w-100 justify-content-between">
          <div className="p-1">
            <h4 className="text-primary">
              <Trans i18nKey="mailing.modal_new_title">Nuova postalizzazione - Finalizza</Trans>
            </h4>
            <Breadcrumbs page={3} />
          </div>
        </div>
        <div
          className="d-flex mb-1 mt-1 flex-row gap-2 h-100 align-items-stretch overflow-auto"
          style={{ flexWrap: 'wrap' }}>
          <div className="border p-3 bg-white flex-grow-1">
            <div className="overflow-auto" style={{ height: '100%' }}>
              <div className="d-flex justify-content-between">
                <h4 className="mb-4">Lista invii</h4>
                <div>
                  <DownloadDocumentButton
                    label="Scarica destinatari"
                    className="mb-2"
                    documentUrl={`${config.SERVER_API_URL}/v1/mail_senders/${current_mailer.id}/mail_sender_recipients.csv`}
                    filename={'recipients.csv'}
                    variant="primary"
                    iconRight="fa-download"
                    disabled={csvDisabled}
                  />
                  {/*
                  <DownloadDocumentButton
                    label="Scarica distinta"
                    disabled={csvDisabled}
                    className="mb-2"
                    documentUrl={`${config.SERVER_API_URL}/v1/users/${current_mailer?.user?.id}/mail_senders/${current_mailer.id}.csv`}
                    filename={`distinta-${current_mailer.id}.csv`}
                    variant="primary"
                    iconRight="fa-download"
                  />*/}
                </div>
              </div>
              <div style={{ maxHeight: 620, overflow: 'auto', paddingRight: 10 }}>
                <Accordion defaultActiveKey="0" flush>
                  {Object.values(send_typologies).map((ss, idx) => {
                    let st = send_typologies.find(s => ss.id === s.id)
                    if (recipients_map[ss.id].length === 0) return null
                    return (
                      <>
                        <Accordion.Item eventKey={`${idx}`}>
                          <Accordion.Header className="border">
                            <h5 className="m-0 p-0">
                              {st?.label}: <b>{recipients_map[ss.id].length}</b>
                            </h5>
                          </Accordion.Header>
                          <Accordion.Body className="m-0 p-0 bw-white">
                            <Table striped bordered hover size="sm" className="mb-0">
                              <thead style={{ position: 'sticky', top: 0, background: 'white', width: '101%' }}>
                                <tr>
                                  <th>Soggetto</th>
                                  <th>Indirizzo</th>
                                  <th>CF</th>
                                  <th>Email</th>
                                  <th>Pec</th>
                                  <th>Tipologia invio</th>
                                  <th>Allegati</th>
                                  <th>Tracking</th>
                                </tr>
                              </thead>
                              <tbody>
                                {recipients_map[ss.id].map(e => {
                                  return (
                                    <tr style={{ height: '1rem', verticalAlign: 'middle' }} key={`e-${e.id}`}>
                                      <td style={{}}>{e.name}</td>
                                      <td>
                                        {e?.address?.street ?? e?.street} - {e?.address?.cap} {e?.address?.city?.name}
                                      </td>
                                      <td>{e.fiscal_identifier}</td>
                                      <td>{e.email}</td>
                                      <td>{e.pec}</td>
                                      <td>{send_typologies.find(s => s.id === e.send_typology)?.label ?? ''}</td>
                                      <td>
                                        <ModalAttachmentsTable
                                          attachments={attachments}
                                          selected={ar_map[e.id]}
                                          poTrans={poTrans}
                                          documentUrl={`${config.SERVER_API_URL}/v1/mail_senders/${current_mailer.id}/attachments/`}
                                        />
                                      </td>
                                      <td>
                                        <Tracking
                                          disabled={allDisabled || current_mailer.current_state !== 'working'}
                                          element={e}
                                          isCondominiaAdmin={isCondominiaAdmin}
                                          submitTrackingcode={this.submitTrackingcode}
                                          submitSent={this.submitSent}
                                        />
                                      </td>
                                    </tr>
                                  )
                                })}
                                {recipients_map[ss.id].length === 0 && (
                                  <tr>
                                    <td colSpan="7" className="text-center pt-3">
                                      Nessun elemento trovato
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </Table>
                          </Accordion.Body>
                        </Accordion.Item>
                        <div className="mt-4"></div>
                      </>
                    )
                  })}
                </Accordion>
                <Accordion defaultActiveKey={'allegati'} alwaysOpen flush>
                  <Accordion.Item eventKey="allegati">
                    <Accordion.Header className="border">
                      <h6>Lista allegati</h6>
                    </Accordion.Header>
                    <Accordion.Body className="m-0 p-0 bg-white">
                      <AttachmentsTable
                        attachments={attachments}
                        poTrans={poTrans}
                        documentUrl={`${config.SERVER_API_URL}/v1/mail_senders/${current_mailer.id}/attachments/`}
                        recipients={recipients}
                      />
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between w-100 align-items-end mt-2" style={{ flex: 1 }}>
          <div className="d-flex align-items-center">
            <Button
              variant={current_mailer?.current_state === 'open' ? 'warning' : 'secondary'}
              className="float-md-end"
              onClick={this.handleDelete}
              disabled={
                current_mailer?.current_state !== 'open' && typeof current_mailer.current_state !== 'undefined'
              }>
              Cancella
            </Button>
            <PriceList />
          </div>
          <div className="d-flex">
            {current_mailer.current_state === 'open' && (
              <Link to={`/mailing/summary/${current_mailer.id}`} className="me-3">
                <Button variant="secondary" className="float-md-end">
                  <i className={`fa fa-arrow-left`} /> Indietro
                </Button>
              </Link>
            )}
            {current_mailer.current_state === 'open' && current_mailer.current_state !== 'working' && (
              <Button
                className="float-md-end"
                onClick={this.onConfirm}
                variant={current_mailer.current_state === 'open' ? 'primary' : 'secondary'}
                disabled={
                  current_mailer?.current_state !== 'open' || recipients.length === 0 || attachments.length === 0
                }>
                Finalizza <i className={`fa fa-arrow-right`} />
              </Button>
            )}
            {current_mailer.current_state === 'working' && !isCondominiaAdmin && (
              <Button
                className="float-md-end me-2"
                onClick={this.onConfirm}
                variant={current_mailer.current_state === 'working' ? 'secondary' : 'secondary'}
                disabled={
                  current_mailer?.current_state !== 'working' || recipients.length === 0 || attachments.length === 0
                }>
                Termina lavorazione <i className={`fa fa-arrow-right`} />
              </Button>
            )}
            {current_mailer.current_state === 'confirmed' && !isCondominiaAdmin && (
              <Button
                className="float-md-end me-2"
                onClick={this.acceptMailSender}
                variant={'success'}
                disabled={current_mailer?.current_state !== 'confirmed'}>
                Accetta <i className={`fa fa-arrow-right`} />
              </Button>
            )}
            {current_mailer.current_state !== 'open' && (
              <Link to={'/mailing'}>
                <Button variant="primary" className="float-md-end">
                  <i className={`fa fa-times`} /> Chiudi
                </Button>
              </Link>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default connect(mstp)(withJWTSession(Finalize))
