import React from 'react'
import { Table, Modal, Button } from 'react-bootstrap'

class RecipientsTable extends React.PureComponent {
  render() {
    let { recipients } = this.props
    return (
      <Table striped bordered hover size="sm">
        <thead style={{ position: 'sticky', top: 0, background: 'white', width: '101%' }}>
          <tr>
            <th className="border">Soggetto</th>
            <th className="border">Indirizzo</th>
            <th className="border">Email</th>
            <th className="border">Pec</th>
          </tr>
        </thead>
        <tbody style={{ maxHeight: 470, overflow: 'auto' }}>
          {recipients.map(e => {
            return (
              <tr key={`r-${e.id}`}>
                <td>{e.name}</td>
                <td>
                  {e?.address?.street ?? e?.street} - {e?.address?.cap} {e?.address?.city?.name}
                  {e?.address?.street ? '' : <i className="fa fa-xmark text-success" />}
                </td>
                <td>{e?.email ?? <i className="fa fa-xmark text-success" />}</td>
                <td>{e?.pec ?? <i className="fa fa-xmark text-success" />}</td>
              </tr>
            )
          })}
          {recipients.length === 0 && (
            <tr>
              <td colSpan="7" className="text-center pt-3 w-100">
                Nessun elemento trovato
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    )
  }
}

export class ModalRecipientsTable extends React.PureComponent {
  state = { show: false }

  toggleModal = () => this.setState({ show: !this.state.show })

  render() {
    let { show } = this.state
    let { attachment, recipients } = this.props
    let selected_recipients = attachment.attachment_options?.[0]?.recipient_ids ?? recipients.map(r => parseInt(r.id))
    let destinations = recipients.filter(r => selected_recipients.includes(parseInt(r.id)))
    return (
      <>
        <Modal show={show} size="lg">
          <Modal.Header>
            <h5>Lista destinatari</h5>
          </Modal.Header>
          <Modal.Body>
            <RecipientsTable recipients={destinations} />
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="secondary" onClick={this.toggleModal}>
              <i className={`fa fa-times`} /> Chiudi
              <div />
            </Button>
          </Modal.Footer>
        </Modal>
        <div onClick={this.toggleModal}>
          <i className="fa fa-users text-primary" /> {selected_recipients.length}
        </div>
      </>
    )
  }
}

export default RecipientsTable
