import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import config from 'config'
import i18next from 'i18next'

import jsonapiToData from 'lib/jsonapi_to_data'
import { error, success } from 'components/system_wide/notification'
const { t } = i18next

const initialState = {
  id: null,
  email: '',
  name: '',
  surname: '',
  role_id: null,
  info: {},
  user_settings: {},
  errors: {},
}

export const setCurrentUser = createAsyncThunk('userInfo/set_user', async (id, thunkApi) => {
  try {
    let user_resp = await axios({
      url: `${config.SERVER_API_URL}/v1/users/${id}?include=tax_datum`,
      method: 'GET',
    })
    let { data: user_data } = user_resp.data
    let role_id = user_data.relationships.role?.data?.id || null
    let tax_datum_id = user_data.relationships.tax_datum.data?.id || null
    let tax_datum_data = {}
    if (tax_datum_id) {
      let tax_resp = await axios({
        url: `${config.SERVER_API_URL}/v1/users/${id}/tax_data/${tax_datum_id}`,
        method: 'GET',
      })
      tax_datum_data = tax_resp.data.data
    }
    let city_id = tax_datum_data.relationships?.city?.data?.id || null
    let attributes = Object.entries(tax_datum_data?.attributes ?? {}).reduce((acc, [key, value]) => {
      return { ...acc, [key]: value ? value : '' }
    }, {})
    let region_id, province_id
    if (city_id) {
      let city_resp = await axios({
        url: `${config.SERVER_API_URL}/v1/cities/${city_id}`,
        method: 'GET',
      })
      region_id = city_resp.data.data.attributes.region_id
      province_id = city_resp.data.data.attributes.province_id
    }
    return {
      id: user_data.id,
      role_id,
      ...user_data.attributes,
      tax_datum_attributes: { ...attributes, city_id, region: region_id, province: province_id, id: tax_datum_id },
    }
  } catch (err) {
    console.log(err)
    return thunkApi.rejectWithValue()
  }
})

export const getUserSettings = createAsyncThunk('users/getUserSettings', async (user_id, thunkApi) => {
  try {
    let state = thunkApi.getState().userInfo
    let user = state.id
    if (user_id) user = user_id
    let response = await axios({
      url: `${config.SERVER_API_URL}/v1/users/${user}/settings?include=settable.user_setting`,
      method: 'GET',
    })
    let userSettings = jsonapiToData(response.data)?.data ?? {}
    let settings = userSettings.map(s => {
      let { settable, ...rest } = s
      return { ...rest, ...settable }
    })
    return settings
  } catch (err) {
    console.log(err)
    error(t('store.users.get_user_settings_error', "Impossibile recuperare le configurazioni dell'utente!"))
    return thunkApi.rejectWithValue()
  }
})

export const saveUserSettings = createAsyncThunk('users/saveUserSettings', async ({ data, user_id }, thunkApi) => {
  try {
    let state = thunkApi.getState().userInfo
    let user = state.id
    if (user_id) user = user_id
    let method = data.id ? 'PATCH' : 'POST'
    let url = `${config.SERVER_API_URL}/v1/users/${user}/settings/smtp_servers?include=user,settable`
    if (data.id)
      url = `${config.SERVER_API_URL}/v1/users/${user}/settings/smtp_servers/${data.id}?include=user,settable`
    await axios({ url, method, data: { ...data } })
    thunkApi.dispatch(getUserSettings(user))
    success(t('store.users.get_user_settings_ok', 'Configurazioni salvate con successo!'))
  } catch (err) {
    console.log(err)
    error(t('store.users.get_user_settings_error', "Impossibile salvare le configurazioni dell'utente!"))
    return thunkApi.rejectWithValue()
  }
})

export const deleteSetting = createAsyncThunk('users/deleteSetting', async (id, thunkApi) => {
  try {
    let state = thunkApi.getState().userInfo
    await axios({
      url: `${config.SERVER_API_URL}/v1/users/${state.id}/settings/smtp_servers/${id}`,
      method: 'DELETE',
    })
    thunkApi.dispatch(getUserSettings())
  } catch (err) {
    console.log(err)
    error(t('store.users.delete_user_settings_error', 'Impossibile eliminare la configurazione!'))
    return thunkApi.rejectWithValue()
  }
})

export const userInfoSlice = createSlice({
  name: 'userInfo',
  initialState,

  reducers: {
    updateUserinfo: (_, action) => action.payload,
    updateErrors: (state, action) => {
      state.errors = action.payload
    },
  },
  extraReducers: {
    [setCurrentUser.fulfilled]: (state, action) => {
      state.info = action.payload
    },
    [setCurrentUser.rejected]: state => {
      state.info = {}
    },
    [getUserSettings.fulfilled]: (state, action) => {
      if (!action?.payload) return
      state.user_settings = action.payload
    },
    [getUserSettings.rejected]: state => {
      state.user_settings = {}
    },
  },
})

export const { updateUserinfo, updateErrors } = userInfoSlice.actions
export default userInfoSlice.reducer
