import React from 'react'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { connect } from 'react-redux'
import { Button, Table, Form } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import {
  getMailer,
  getMailerAttachments,
  getRecipients,
  getPrintOptions,
  deleteMailer,
  createAttachment,
  deleteAttachment,
  updateRecipient,
  createRecipient,
  getValidators,
} from 'store/mailing'
import withJWTSession from 'enhancers/jwt_session'
import config from 'config'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import DownloadDocumentButton, { downloadFile } from 'components/system_wide/download_document_button'
import AddAttachment from './add_attachment'
import StyledSelect from '../system_wide/styled_select'
import ActionsDropdownSplitted from 'components/system_wide/actions_dropdown_splitted'
import { ModalRecipientsTable } from './recipients_table'
import { isAdmin, isCondominiaAdmin, send_typologies } from 'lib/utils'
import PriceList from './price_list'
import AddRecipient from './new_recipient'
import Breadcrumbs from './components/breadcrumbs'

const { t } = i18next

const mstp = state => {
  let { administrators } = state.condominia
  let { condominia, users, validators, errors, current_mailer, recipients, print_options } = state.mailing
  let { role_id, id: my_user_id } = state.userInfo
  let { roles } = state.usersData
  let { strings: trans } = state.translations
  return {
    administrators,
    condominia,
    users,
    my_user_id,
    validators,
    errors,
    isAdmin: isAdmin(role_id, roles),
    isCondominiaAdmin: isCondominiaAdmin(role_id, roles),
    current_mailer,
    trans,
    recipients,
    print_options,
  }
}

class Summary extends React.Component {
  static defaultProps = {
    elements: [],
  }
  state = {
    selectedFile: '',
    selectedFileLabel: '',
    selected_options: {},
    selected_typology: '',
    edit_recipient_modal: false,
    edit_recipient: {},
  }

  async componentDidMount() {
    await this.props.dispatch(getValidators())
    if (this.props.router.params.mailer_id) {
      await this.props.dispatch(getMailer(this.props.router.params.mailer_id))
      await this.props.dispatch(getMailerAttachments(this.props.router.params.mailer_id))
      await this.props.dispatch(getRecipients(this.props.router.params.mailer_id))
    }
    if (this.props.print_options.length === 0) {
      await this.props.dispatch(getPrintOptions())
    }
    this.setState({
      selected_options: {
        ...Object.keys(this.props.print_options).reduce(
          (acc, o) => ({ ...acc, [o]: this.props.print_options[o].find(op => op.default === true)?.id }),
          {}
        ),
      },
    })
  }
  handleDelete = async () => {
    let { current_mailer } = this.props
    showConfirmDialog(
      'Elimina postalizzazione',
      'Sei sicuro di voler annullare la postalizzazione in corso? Tutti i dati inseriti andranno persi!',
      async () => {
        if (current_mailer.id) await this.props.dispatch(deleteMailer(current_mailer.id))
        this.props.navigate('/mailing')
      },
      () => {},
      'danger',
      'Annulla postalizzazione'
    )
  }
  onConfirm = async () => {
    let { current_mailer } = this.props
    this.props.router.navigate(`/mailing/summary/${current_mailer.id}`)
  }
  FileUploadHandler = e => {
    this.setState({ selectedFile: e.target.files[0] })
  }
  setUploadForm = form => {
    this.uploadForm = form
  }
  checkOptions = (o, id) => () => {
    this.setState({ selected_options: { ...this.state.selected_options, [o]: [id] } })
  }
  uploadFile = async (recipients = []) => {
    let { selectedFile, selected_options } = this.state
    let { my_user_id } = this.props

    let formData = new FormData()
    formData.append('_jsonapi[document]', selectedFile)
    Object.keys(selected_options).forEach(o => {
      let pr_opt = selected_options[o]
      formData.append('_jsonapi[associable_print_options_attributes][][print_option_id]', pr_opt)
    })
    await this.props.dispatch(createAttachment({ user_id: my_user_id, data: formData, recipients }))
    await this.props.dispatch(getMailerAttachments(this.props.router.params.mailer_id))
    this.uploadForm.value = ''
    this.setState({
      selected_options: {
        ...Object.keys(this.props.print_options).reduce(
          (acc, o) => ({ ...acc, [o]: this.props.print_options[o].find(op => op.default === true)?.id }),
          {}
        ),
      },
    })
  }
  handleDeleteAttachment = async id => {
    await this.props.dispatch(deleteAttachment(id))
    await this.props.dispatch(getMailerAttachments(this.props.router.params.mailer_id))
  }

  regenerateRecipient = (recipient_id, value, send_digital_copy = false) => {
    let { recipients } = this.props
    let recipient = { ...recipients.find(r => r.id === recipient_id) }
    recipient.send_digital_copy = send_digital_copy
    if (value) recipient.send_typology = value
    recipient.address_attributes = recipient.address
    delete recipient.address
    return recipient
  }
  handleChangeSendTypology =
    recipient_id =>
    async ({ value }) => {
      let { my_user_id: user_id } = this.props
      let mailer_id = this.props.router.params.mailer_id
      let recipient = this.regenerateRecipient(recipient_id, value)
      await this.props.dispatch(updateRecipient({ user_id, mailer_id, recipient }))
    }

  handleSetDigitalCopy = recipient_id => async e => {
    let { my_user_id: user_id } = this.props
    let mailer_id = this.props.router.params.mailer_id
    let recipient = this.regenerateRecipient(recipient_id, null, e.target.checked)
    await this.props.dispatch(updateRecipient({ user_id, mailer_id, recipient }))
  }

  handleChangeTypologyForAll = async ({ value }) => {
    this.setState({ selected_typology: value })
  }
  handleSetDigitalCopyAll = async e => {
    this.setState({ selected_send_digital_copy: e.target.checked ?? false })
  }
  changeAllTypologyForAll = async () => {
    let { recipients } = this.props
    let { selected_typology, selected_send_digital_copy } = this.state
    for (const r of recipients) {
      let { my_user_id: user_id } = this.props
      let mailer_id = this.props.router.params.mailer_id
      let recipient = this.regenerateRecipient(r.id, selected_typology, selected_send_digital_copy)
      if (!selected_typology.includes('paper') && !recipient.email && !recipient.pec) return
      await this.props.dispatch(updateRecipient({ user_id, mailer_id, recipient }))
    }
    this.setState({ selected_typology: '', selected_send_digital_copy: '' })
  }
  openEditRecipient = recipient => () => {
    let { id, name, fiscal_identifier, pec, email, address = {} } = recipient
    let { street, cap, city_id, country_id, city = {} } = address
    let { province_id = '', region_id = '' } = city
    let rep = {
      id,
      name,
      fiscal_identifier,
      street,
      pec,
      email,
      region: region_id,
      province: province_id,
      cap,
      city_id,
      country_id,
    }
    this.setState({ edit_recipient: rep, edit_recipient_modal: true })
  }
  addNewRecipient = async data => {
    let { current_mailer, my_user_id } = this.props
    await this.props.dispatch(createRecipient({ user_id: my_user_id, mailer_id: current_mailer.id, data }))
    await this.props.dispatch(getRecipients())
    this.setState({ edit_recipient: {}, edit_recipient_modal: false })
  }

  actions = [
    {
      icon: 'fa-trash',
      text: t('actions_dropdown.delete_mailing', 'Elimina'),
      onClick: ({ id }) => {
        this.handleDeleteAttachment(id)
      },
    },
  ]
  actionsRenderer = rowData => {
    let { current_mailer, isCondominiaAdmin } = this.props
    let documentUrl = `${config.SERVER_API_URL}/v1/mail_senders/${current_mailer.id}/attachments/${rowData.id}`
    if (isCondominiaAdmin) {
      return (
        <ActionsDropdownSplitted
          label="Scarica"
          rowData={rowData}
          actions={this.actions}
          onClickMaster={() => downloadFile(documentUrl, rowData.name)}
          height={35}
        />
      )
    } else {
      return (
        <DownloadDocumentButton
          className=""
          documentUrl={`${config.SERVER_API_URL}/v1/mail_senders/${current_mailer.id}/attachments/${rowData.id}`}
          filename={rowData.name}
          variant="primary"
          size="sm"
          iconRight="fa-download"
        />
      )
    }
  }

  render() {
    let { current_mailer, recipients, trans, print_options, validators } = this.props
    let attachments = current_mailer?.attachments ?? []
    let { print_options: poTrans } = trans?.tables || {}
    let {
      selectedFile,
      selectedFileLabel,
      selected_options,
      selected_typology,
      selected_send_digital_copy,
      edit_recipient_modal,
      edit_recipient,
    } = this.state

    let main_attachment_id = current_mailer.main_attachment_id ?? attachments?.[0]?.id ?? ''
    let main_attachment = attachments.find(el => parseInt(el.id) === parseInt(main_attachment_id))
    let main_print_opts = (main_attachment?.print_options ?? []).map(p => poTrans?.[p.category]?.[p.value] ?? p.value)

    let allDisabled = current_mailer?.current_state !== 'open' && typeof current_mailer.current_state !== 'undefined'

    return (
      <>
        <div className="d-flex flex-column w-100 overflow-auto m-2 ms-3 me-3 z-1">
          <div className="d-flex flex-wrap gap-2 mb-2 w-100 justify-content-between">
            <div className="p-1">
              <h4 className="text-primary">
                <Trans i18nKey="mailing.modal_new_title">Nuova postalizzazione - Imposta tipologia di invio</Trans>
              </h4>
              <Breadcrumbs page={2} />
            </div>
          </div>
          <div className="d-flex mb-1 mt-1 flex-row gap-2 h-100 align-items-stretch" style={{ flexWrap: 'wrap' }}>
            <div className="border p-3 bg-white flex-grow-1">
              <div className="overflow-auto" style={{ height: '100%' }}>
                <div className="d-flex justify-content-between">
                  <h5 className="mb-3">
                    Lista destinatari - Selezionati: <b>{recipients.length} destinatari</b>
                    {current_mailer.current_state !== 'delegated' && (
                      <DownloadDocumentButton
                        className="mb-2"
                        documentUrl={`${config.SERVER_API_URL}/v1/mail_senders/${current_mailer.id}/mail_sender_recipients.csv`}
                        filename={'recipients.csv'}
                        variant="primary"
                        iconRight="fa-download"
                      />
                    )}
                  </h5>
                  <div className="d-flex align-items-top">
                    <div style={{ minWidth: 200 }}>
                      <StyledSelect
                        className="ms-2"
                        errors={{}}
                        valid={true}
                        placeholder={'Tipologia di invio'}
                        value={send_typologies.find(s => s.id === selected_typology)}
                        onChange={this.handleChangeTypologyForAll}
                        options={send_typologies}
                        isDisabled={allDisabled}
                      />
                      {selected_typology.includes('paper') && (
                        <Form.Check
                          className="mt-2 ms-2"
                          type="switch"
                          label="Copia digitale?"
                          id="send_digital_copy"
                          checked={selected_send_digital_copy}
                          onChange={this.handleSetDigitalCopyAll}
                        />
                      )}
                    </div>
                    <Button
                      variant="secondary"
                      style={{ height: 38, marginLeft: 5 }}
                      disabled={allDisabled}
                      onClick={this.changeAllTypologyForAll}>
                      Applica a tutti
                    </Button>
                  </div>
                </div>
                <div style={{ maxHeight: 620, overflow: 'auto' }}>
                  <Table striped bordered hover size="sm" style={{ borderCollapse: 'separate', borderSpacing: 0 }}>
                    <thead style={{ position: 'sticky', top: 0, background: 'white', width: '101%', zIndex: 1000 }}>
                      <tr>
                        <th></th>
                        <th className="border">Soggetto</th>
                        <th className="border">Indirizzo</th>
                        <th className="border">CF</th>
                        <th className="border">Email</th>
                        <th className="border">Pec</th>
                        <th className="border">Tipologia invio</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recipients.map(e => {
                        return (
                          <tr style={{ height: '1rem', verticalAlign: 'middle' }} key={`e-${e.id}`}>
                            <td style={{ maxWidth: '1.2rem' }}>
                              {e.name !== 'TEMPLATE' && (
                                <Button
                                  size="sm"
                                  className="p-1"
                                  variant="secondary"
                                  onClick={this.openEditRecipient(e)}>
                                  <i className="fa fa-pencil" />
                                </Button>
                              )}
                            </td>
                            <td style={{}}>{e.name}</td>
                            <td style={{ maxWidth: '15rem' }}>
                              {e?.address?.street ?? e?.street} - {e?.address?.cap} {e?.address?.city?.name}
                            </td>
                            <td>{e.fiscal_identifier}</td>
                            <td>{e.email}</td>
                            <td>{e.pec}</td>
                            <td>
                              {allDisabled ? (
                                send_typologies.find(s => s.id === e.send_typology)?.label
                              ) : (
                                <div style={{ minWidth: '10rem' }}>
                                  <StyledSelect
                                    errors={{}}
                                    valid={true}
                                    placeholder={''}
                                    value={send_typologies.find(s => s.id === e.send_typology)}
                                    onChange={this.handleChangeSendTypology(e.id)}
                                    options={send_typologies.filter(s => {
                                      if (!s.id.includes('paper')) {
                                        if (e.email || e.pec) return s
                                      } else {
                                        return s
                                      }
                                    })}
                                    menuPosition="fixed"
                                  />
                                  {e.send_typology.includes('paper') && (e.email || e.pec) && (
                                    <Form.Check
                                      className="mt-2"
                                      type="switch"
                                      label="Copia digitale?"
                                      id="send_digital_copy"
                                      checked={e.send_digital_copy}
                                      onChange={this.handleSetDigitalCopy(e.id)}
                                    />
                                  )}
                                </div>
                              )}
                            </td>
                          </tr>
                        )
                      })}
                      {recipients.length === 0 && (
                        <tr>
                          <td colSpan="6" className="text-center pt-3">
                            Nessun elemento trovato - impossibile avviare la postalizzazione
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <div className="border p-3 bg-white flex-grow-1">
              <div className="row">
                <div className="mb-4 mt-2">
                  <h5 className="">File da inviare</h5>
                  <div className="mb-3" style={{ fontStyle: 'italic', fontSize: 13 }}>
                    * Gli allegati vengono inviati nella loro interezza ai destinatari selezionati
                  </div>
                  {main_attachment && (
                    <>
                      <div>Allegato principale:</div>
                      <Table striped bordered hover size="sm">
                        <thead>
                          <tr>
                            <th>File</th>
                            <th className="text-center" style={{ width: 110 }}>
                              Scarica
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr style={{ height: '1rem', verticalAlign: 'middle' }} key={`main-${main_attachment.id}`}>
                            <td>
                              <div>{main_attachment.name}</div>
                              <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                                {main_print_opts.join(', ')}
                              </div>
                            </td>
                            <td className="text-center" style={{ width: 110 }}>
                              <DownloadDocumentButton
                                className=""
                                documentUrl={`${config.SERVER_API_URL}/v1/mail_senders/${current_mailer.id}/attachments/${main_attachment.id}`}
                                filename={main_attachment.name}
                                variant="primary"
                                size="sm"
                                iconRight="fa-download"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </>
                  )}
                  <div className="mt-5 d-flex justify-content-between">
                    Allegati aggiuntivi:
                    <AddAttachment
                      size="sm"
                      errors={{}}
                      selectedFile={selectedFile}
                      selectedFileLabel={selectedFileLabel}
                      FileUploadHandler={this.FileUploadHandler}
                      setUploadForm={this.setUploadForm}
                      print_options={print_options}
                      poTrans={poTrans}
                      selected_options={selected_options}
                      checkOptions={this.checkOptions}
                      uploadFile={this.uploadFile}
                      selectRecipients={true}
                      recipients={recipients}
                      disabled={allDisabled}
                    />
                  </div>
                  <Table striped bordered hover size="sm" className="mt-3">
                    <thead>
                      <tr>
                        <th>File</th>
                        <th className="text-center" style={{ width: 40 }}>
                          Destinatari
                        </th>
                        <th className="text-center" style={{ width: 110 }}>
                          Azioni
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {attachments
                        .filter(dw => parseInt(dw.id) !== parseInt(main_attachment_id))
                        .map(dw => {
                          let print_opts = (dw?.print_options ?? []).map(
                            p => poTrans?.[p.category]?.[p.value] ?? p.value
                          )
                          return (
                            <tr style={{ height: '1rem', verticalAlign: 'middle' }} key={`dw-${dw.id}`}>
                              <td>
                                <div>{dw.name}</div>
                                <div className="text-muted" style={{ fontSize: '0.8rem' }}>
                                  {print_opts.join(', ')}
                                </div>
                              </td>
                              <td className="text-center">
                                <ModalRecipientsTable recipients={recipients} attachment={dw} />
                              </td>
                              <td className="text-center" style={{ maxWidth: 110 }}>
                                {this.actionsRenderer(dw)}
                              </td>
                            </tr>
                          )
                        })}
                    </tbody>
                  </Table>
                </div>
                <div className="text-muted mb-4"></div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-between w-100 align-items-end mt-2" style={{ flex: 1 }}>
            <div className="d-flex align-items-center">
              <Button
                variant={current_mailer?.current_state === 'open' ? 'warning' : 'secondary'}
                className="float-md-end"
                onClick={this.handleDelete}
                disabled={
                  current_mailer?.current_state !== 'open' && typeof current_mailer.current_state !== 'undefined'
                }>
                Cancella
              </Button>
              <PriceList />
            </div>
            <div className="d-flex">
              <Link to={`/mailing/select_targets/${current_mailer.id}`} className="me-3">
                <Button variant="secondary" className="float-md-end">
                  <i className={`fa fa-arrow-left`} /> Indietro
                </Button>
              </Link>
              <Link to={`/mailing/finalize/${current_mailer.id}`} className="">
                <Button variant="primary" className="float-md-end">
                  Procedi <i className={`fa fa-arrow-right`} />
                </Button>
              </Link>
            </div>
          </div>
        </div>
        {edit_recipient_modal && (
          <AddRecipient
            recipient={edit_recipient}
            closeModal={() => this.setState({ edit_recipient_modal: false })}
            onSave={this.addNewRecipient}
            validators={validators}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(withJWTSession(Summary))
