import * as React from 'react'
import { connect } from 'react-redux'
import { HexGrid, Layout, Hexagon, Text } from 'react-hexgrid'
import { isCondominiaAdmin, isDev } from 'lib/utils'
import WithCapabilities from 'enhancers/with_capabilities'

import 'scss/hexagon.css'

const mstp = state => {
  let { roles } = state.usersData
  return {
    userInfo: state.userInfo,
    isCondominiaAdmin: isCondominiaAdmin(state.userInfo?.role_id, roles),
  }
}

class HexagonGrid extends React.Component {
  getWindowDimensions = () => {
    const { innerWidth: width, innerHeight: height } = window
    return {
      width,
      height,
    }
  }

  handleResize = () => {
    this.forceUpdate()
  }
  componentDidMount() {
    window.addEventListener('resize', this.handleResize)
  }
  render() {
    let { navigate, userInfo, isCondominiaAdmin } = this.props
    const { innerWidth: width, innerHeight: height } = window
    let postalization_enabled = userInfo?.info?.enabled_features?.postalization === true
    if (!isCondominiaAdmin) postalization_enabled = true

    return (
      <HexGrid width={width - 60} height={height - 55} viewBox="10 -70 100 100">
        <Layout size={{ x: 19, y: 19 }} flat={false} spacing={1.03} origin={{ x: 15, y: -35 }}>
          <Hexagon q={1} r={-1} s={0} className="grey" />
          <Hexagon q={2} r={-1} s={0} className="grey" />
          <Hexagon q={3} r={-1} s={0} className="grey" />
          <Hexagon q={-1} r={0} s={0} className="grey" />
          <Hexagon q={0} r={0} s={0} onClick={() => navigate('/condominia')}>
            <image
              x="-0.2rem"
              y="-0.6rem"
              href='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M436 480h-20V24c0-13.255-10.745-24-24-24H56C42.745 0 32 10.745 32 24v456H12c-6.627 0-12 5.373-12 12v20h448v-20c0-6.627-5.373-12-12-12zM128 76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76zm0 96c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40zm52 148h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40c0 6.627-5.373 12-12 12zm76 160h-64v-84c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v84zm64-172c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12v-40c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40zm0-96c0 6.627-5.373 12-12 12h-40c-6.627 0-12-5.373-12-12V76c0-6.627 5.373-12 12-12h40c6.627 0 12 5.373 12 12v40z" fill="white" /></svg>'
              height="7"
              width="7"
            />
            <Text y="3">LISTA</Text>
            <Text y="7">CONDOMINI</Text>
          </Hexagon>
          <Hexagon q={1} r={0} s={0} className="grey" />
          <Hexagon
            q={1}
            r={0}
            s={0}
            className={`${postalization_enabled ? '' : 'darkgrey'}`}
            onClick={() => (postalization_enabled ? navigate('/mailing') : null)}>
            <image
              x="-0.2rem"
              y="-0.6rem"
              href='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M176 216h160c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16H176c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16zm-16 80c0 8.8 7.2 16 16 16h160c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16H176c-8.8 0-16 7.2-16 16v16zm96 121.1c-16.4 0-32.8-5.1-46.9-15.2L0 250.9V464c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48V250.9L302.9 401.9c-14 10.1-30.4 15.2-46.9 15.2zm237.6-254.2c-8.9-6.9-17.2-13.5-29.6-22.8V96c0-26.5-21.5-48-48-48h-77.6c-3-2.2-5.9-4.3-9-6.6C312.6 29.2 279.2-.4 256 0c-23.2-.4-56.6 29.2-73.4 41.4-3.2 2.3-6 4.4-9 6.6H96c-26.5 0-48 21.5-48 48v44.1c-12.4 9.3-20.8 15.9-29.6 22.8A48 48 0 0 0 0 200.7v10.7l96 69.4V96h320v184.7l96-69.4v-10.7c0-14.7-6.8-28.7-18.4-37.8z" fill="white"/></svg>'
              height="7"
              width="7"
            />
            <Text y="7">POSTALIZZAZIONE</Text>
          </Hexagon>
          <Hexagon q={2} r={0} s={0} onClick={() => navigate('/contracts')}>
            <image
              x="-0.2rem"
              y="-0.6rem"
              href='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M292.7 342.3C289.7 345.3 288 349.4 288 353.7V416h62.34c4.264 0 8.35-1.703 11.35-4.727l156.9-158l-67.88-67.88L292.7 342.3zM568.5 167.4L536.6 135.5c-9.875-10-26-10-36 0l-27.25 27.25l67.88 67.88l27.25-27.25C578.5 193.4 578.5 177.3 568.5 167.4zM256 0v128h128L256 0zM256 448c-16.07-.2852-30.62-9.359-37.88-23.88c-2.875-5.875-8-6.5-10.12-6.5s-7.25 .625-10 6.125l-7.749 15.38C187.6 444.6 181.1 448 176 448H174.9c-6.5-.5-12-4.75-14-11L144 386.6L133.4 418.5C127.5 436.1 111 448 92.45 448H80C71.13 448 64 440.9 64 432S71.13 416 80 416h12.4c4.875 0 9.102-3.125 10.6-7.625l18.25-54.63C124.5 343.9 133.6 337.3 144 337.3s19.5 6.625 22.75 16.5l13.88 41.63c19.75-16.25 54.13-9.75 66 14.12C248.5 413.2 252.2 415.6 256 415.9V347c0-8.523 3.402-16.7 9.451-22.71L384 206.5V160H256c-17.67 0-32-14.33-32-32L224 0H48C21.49 0 0 21.49 0 48v416C0 490.5 21.49 512 48 512h288c26.51 0 48-21.49 48-48V448H256z" fill="white"/></svg>'
              height="7"
              width="7"
            />
            <Text y="7">CONTRATTI</Text>
          </Hexagon>
          <Hexagon q={3} r={0} s={0} className="grey" />
          <Hexagon q={-2} r={1} s={0} className="grey" />
          <Hexagon q={-1} r={1} s={0} onClick={() => navigate('/transfers')}>
            <image
              x="-0.2rem"
              y="-0.6rem"
              href='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!-- Font Awesome Pro 5.15.4 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) --><path d="M504.971 359.029c9.373 9.373 9.373 24.569 0 33.941l-80 79.984c-15.01 15.01-40.971 4.49-40.971-16.971V416h-58.785a12.004 12.004 0 0 1-8.773-3.812l-70.556-75.596 53.333-57.143L352 336h32v-39.981c0-21.438 25.943-31.998 40.971-16.971l80 79.981zM12 176h84l52.781 56.551 53.333-57.143-70.556-75.596A11.999 11.999 0 0 0 122.785 96H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12zm372 0v39.984c0 21.46 25.961 31.98 40.971 16.971l80-79.984c9.373-9.373 9.373-24.569 0-33.941l-80-79.981C409.943 24.021 384 34.582 384 56.019V96h-58.785a12.004 12.004 0 0 0-8.773 3.812L96 336H12c-6.627 0-12 5.373-12 12v56c0 6.627 5.373 12 12 12h110.785c3.326 0 6.503-1.381 8.773-3.812L352 176h32z" fill="white"/></svg>'
              height="7"
              width="7"
            />
            <Text y="3">LISTA</Text>
            <Text y="7">TRASFERIMENTI</Text>
          </Hexagon>
          <Hexagon q={0} r={1} s={0} className="grey" />
          <Hexagon
            q={0}
            r={1}
            s={0}
            className={isDev() ? '' : 'darkgrey'}
            onClick={() => isDev() && navigate('/assignments')}>
            <image
              x="-0.2rem"
              y="-0.6rem"
              href='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M88 48C101.3 48 112 58.75 112 72V120C112 133.3 101.3 144 88 144H40C26.75 144 16 133.3 16 120V72C16 58.75 26.75 48 40 48H88zM480 64C497.7 64 512 78.33 512 96C512 113.7 497.7 128 480 128H192C174.3 128 160 113.7 160 96C160 78.33 174.3 64 192 64H480zM480 224C497.7 224 512 238.3 512 256C512 273.7 497.7 288 480 288H192C174.3 288 160 273.7 160 256C160 238.3 174.3 224 192 224H480zM480 384C497.7 384 512 398.3 512 416C512 433.7 497.7 448 480 448H192C174.3 448 160 433.7 160 416C160 398.3 174.3 384 192 384H480zM16 232C16 218.7 26.75 208 40 208H88C101.3 208 112 218.7 112 232V280C112 293.3 101.3 304 88 304H40C26.75 304 16 293.3 16 280V232zM88 368C101.3 368 112 378.7 112 392V440C112 453.3 101.3 464 88 464H40C26.75 464 16 453.3 16 440V392C16 378.7 26.75 368 40 368H88z" fill="white"/></svg>'
              height="7"
              width="7"
            />
            <Text y="3">LISTA</Text>
            <Text y="7">INCARICHI</Text>
          </Hexagon>
          <Hexagon
            q={1}
            r={1}
            s={0}
            className={isDev() ? '' : 'darkgrey'}
            onClick={() => isDev() && navigate('/stats')}>
            <image
              x="-0.2rem"
              y="-0.6rem"
              href='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M64 400C64 408.8 71.16 416 80 416H480C497.7 416 512 430.3 512 448C512 465.7 497.7 480 480 480H80C35.82 480 0 444.2 0 400V64C0 46.33 14.33 32 32 32C49.67 32 64 46.33 64 64V400zM342.6 278.6C330.1 291.1 309.9 291.1 297.4 278.6L240 221.3L150.6 310.6C138.1 323.1 117.9 323.1 105.4 310.6C92.88 298.1 92.88 277.9 105.4 265.4L217.4 153.4C229.9 140.9 250.1 140.9 262.6 153.4L320 210.7L425.4 105.4C437.9 92.88 458.1 92.88 470.6 105.4C483.1 117.9 483.1 138.1 470.6 150.6L342.6 278.6z" fill="white"/></svg>'
              height="7"
              width="7"
            />
            <Text y="7">STATISTICHE</Text>
          </Hexagon>
          <Hexagon q={2} r={1} s={0} className="grey" />
          <Hexagon q={2} r={1} s={0} onClick={() => navigate('/users')}>
            <image
              x="-0.2rem"
              y="-0.6rem"
              href='data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path d="M319.9 320c57.41 0 103.1-46.56 103.1-104c0-57.44-46.54-104-103.1-104c-57.41 0-103.1 46.56-103.1 104C215.9 273.4 262.5 320 319.9 320zM369.9 352H270.1C191.6 352 128 411.7 128 485.3C128 500.1 140.7 512 156.4 512h327.2C499.3 512 512 500.1 512 485.3C512 411.7 448.4 352 369.9 352zM512 160c44.18 0 80-35.82 80-80S556.2 0 512 0c-44.18 0-80 35.82-80 80S467.8 160 512 160zM183.9 216c0-5.449 .9824-10.63 1.609-15.91C174.6 194.1 162.6 192 149.9 192H88.08C39.44 192 0 233.8 0 285.3C0 295.6 7.887 304 17.62 304h199.5C196.7 280.2 183.9 249.7 183.9 216zM128 160c44.18 0 80-35.82 80-80S172.2 0 128 0C83.82 0 48 35.82 48 80S83.82 160 128 160zM551.9 192h-61.84c-12.8 0-24.88 3.037-35.86 8.24C454.8 205.5 455.8 210.6 455.8 216c0 33.71-12.78 64.21-33.16 88h199.7C632.1 304 640 295.6 640 285.3C640 233.8 600.6 192 551.9 192z" fill="white"/></svg>'
              height="7"
              width="7"
            />
            <Text y="3">LISTA</Text>
            <Text y="7">UTENTI</Text>
          </Hexagon>
          <Hexagon q={3} r={1} s={0} className="grey" />
          <Hexagon q={-1} r={2} s={0} className="grey" />
          <Hexagon q={0} r={2} s={0} className="grey" />
          <Hexagon q={1} r={2} s={0} className="grey" />
          <Hexagon q={3} r={2} s={0} className="grey" />
        </Layout>
      </HexGrid>
    )
  }
}

export default connect(mstp)(HexagonGrid)
