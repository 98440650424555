import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import cookies from 'browser-cookies'
import { Dropdown, Alert } from 'react-bootstrap'

import { isAdmin as _isAdmin, isNoTaxDatum as _isNotTaxDatum, isCondominiaAdmin, isNoTaxDatum } from 'lib/utils'
import { resetStore } from 'store'
import { setCurrentUser } from 'store/userinfo'
import { serverCheck } from 'store/common'
import { getTranslations } from 'store/translations'
import UserProfile from 'components/users/user_profile'
import OfflineModal from 'components/system_wide/offline_modal'
import Documents from 'components/system_wide/modal-documents'
import Contracts from 'components/system_wide/modal-contracts'
import ModalTicket from 'components/system_wide/modal-ticker'
import ModalSettings from 'components/system_wide/modal-settings'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import 'scss/navbar.css'
import TruncatedText from './truncated_text'

const mstp = state => {
  let user = state.userInfo
  let { roles } = state.usersData
  let { api_version, current_version } = state.common
  let isAdmin = _isAdmin(user.role_id, roles)
  let isNoTaxDatum = _isNotTaxDatum(user.role_id, roles)
  return {
    user,
    api_version,
    current_version,
    isAdmin,
    isNoTaxDatum,
    isCondominiaAdmin: isCondominiaAdmin(user.role_id, roles),
  }
}

class UserMenuDropdown extends React.PureComponent {
  state = {
    show: false,
    show_docs: false,
    show_contracts: false,
    show_ticket: false,
    show_settings: false,
  }

  timer = null

  pingCheck = async () => {
    try {
      await this.props.dispatch(serverCheck())
    } catch (err) {
      clearInterval(this.timer)
    }
  }

  componentDidMount = async () => {
    await this.props.dispatch(setCurrentUser(this.props.user.id))
    await this.props.dispatch(getTranslations())
    clearInterval(this.timer)
    this.timer = setInterval(() => this.pingCheck(), 30000)
  }

  componentWillUnmount = () => {
    clearInterval(this.timer)
    this.timer = null
  }

  handleShow = mode => () => {
    this.setState({ [`show${mode}`]: !this.state[`show${mode}`] })
  }

  handleLogout = () => {
    this.props.dispatch(resetStore)
    cookies.erase('conhive-auth')
  }

  handleDialogTicket = () => {
    this.setState({ show_ticket: false })
    showConfirmDialog(
      <Trans i18nKey="ticket.modal.title">Richiedi assistenza</Trans>,
      <Alert className="mb-0" variant="info">
        <Trans i18nKey="ticket.confirm_dialog">
          Stiamo aprendo il tuo client di posta per avviare la conversazione di assistenza, qualche secondo... Intanto
          puoi chiudere questa notifica.
        </Trans>
      </Alert>,
      () => {},
      () => {},
      'primary',
      'Chiudi',
      true
    )
  }

  render() {
    let { user, api_version, current_version, isAdmin, isCondominiaAdmin } = this.props
    return (
      <>
        <Dropdown id="user-dropdown-home">
          <Dropdown.Toggle variant="primary">
            <i className={`fas fa-user`} />
          </Dropdown.Toggle>
          <Dropdown.Menu id="user-dropdown-menu">
            <Dropdown.Item>
              <TruncatedText>
                Salve{' '}
                <b>
                  {user?.info?.name} {user?.info?.surname}
                </b>
              </TruncatedText>
            </Dropdown.Item>
            {!isAdmin && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={this.handleShow('_contracts')}>
                  <i className={`fas fa-file-signature pe-2`} style={{ width: '2rem' }} />
                  Il mio studio
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Divider />
            <Dropdown.Item onClick={this.handleShow('_docs')}>
              <i className={`fas fa-file-alt pe-2`} style={{ width: '2rem' }} />
              Documenti generali
            </Dropdown.Item>
            {isNoTaxDatum && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={this.handleShow('')}>
                  <i className={`fas fa-user pe-2`} style={{ width: '2rem' }} />
                  Profilo
                </Dropdown.Item>
              </>
            )}
            {isCondominiaAdmin && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={this.handleShow('_settings')}>
                  <i className={`fas fa-gears pe-2`} style={{ width: '2rem' }} />
                  Impostazioni
                </Dropdown.Item>
              </>
            )}
            {!isAdmin && (
              <>
                <Dropdown.Divider />
                <Dropdown.Item onClick={this.handleShow('_ticket')}>
                  <i className={`fas fa-life-ring pe-2`} style={{ width: '2rem' }} />
                  Richiedi assistenza
                </Dropdown.Item>
              </>
            )}
            <Dropdown.Divider />
            <Dropdown.Item onClick={this.handleLogout}>
              <i className={`fas fa-right-from-bracket pe-2`} style={{ width: '2rem' }} />
              Esci
            </Dropdown.Item>
            <Dropdown.Divider />
            <div className="p-2" style={{ fontSize: 11 }}>
              <div>
                <span className="text-muted">API:</span> {api_version}
              </div>
              <div>
                <span className="text-muted">Interfaccia:</span> {current_version}
              </div>
            </div>
          </Dropdown.Menu>
        </Dropdown>
        {this.state.show && <UserProfile show={this.state.show} handleShow={this.handleShow('')} />}
        {this.state.show_docs && <Documents onCloseModal={this.handleShow('_docs')} />}
        {this.state.show_contracts && <Contracts onCloseModal={this.handleShow('_contracts')} />}
        {this.state.show_ticket && (
          <ModalTicket
            show={this.state.show_ticket}
            onConfirm={this.handleDialogTicket}
            onCloseModal={this.handleShow('_ticket')}
          />
        )}
        {this.state.show_settings && <ModalSettings show={true} onCloseModal={this.handleShow('_settings')} />}
        <OfflineModal />
      </>
    )
  }
}

export default connect(mstp)(UserMenuDropdown)
