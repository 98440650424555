import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import hash from 'object-hash'
import { Col, Nav, Row, Tab, Form, Button, Modal } from 'react-bootstrap'

import StyledSelect from 'components/system_wide/styled_select'
import { smtp_authentications } from 'lib/utils'
import { getUserSettings, saveUserSettings, deleteSetting, updateErrors } from 'store/userinfo'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import validateFields from 'lib/validators/setting'

const mstp = state => {
  let { user_settings, id, errors } = state.userInfo
  return { user_settings, my_id: id, errors }
}

const initialState = {
  email_hash: '',
  pec_hash: '',
  email: {
    user_name: '',
    password: '',
    domain: '',
    address: '',
    port: '465',
    ssl: true,
    tls: false,
    from: '',
    authentication: 'plain',
    enable_starttls_auto: false,
    typology: 'email',
  },
  pec: {
    user_name: '',
    password: '',
    domain: '',
    address: '',
    port: '465',
    ssl: true,
    tls: false,
    from: '',
    authentication: 'plain',
    enable_starttls_auto: false,
    typology: 'pec',
  },
}
class ModalSettings extends React.Component {
  static defaultProps = {
    show: false,
    onCloseModal: () => {},
    onConfirm: () => {},
    errors: {},
  }

  state = {
    activeTab: 'email',
    ...initialState,
  }

  updateHash = setting => {
    let data = this.state[setting]
    this.setState({ [`${setting}_hash`]: hash(data) })
  }

  resetState = (update = false) => {
    let settings = this.props.user_settings
    if (settings.length || update) {
      let newSettings = {}
      settings.forEach(s => {
        newSettings[s.typology] = { ...s }
        newSettings[`${s.typology}_hash`] = hash({ ...s })
      })
      this.props.dispatch(updateErrors({}))
      this.setState({ ...initialState, ...newSettings }, () => {
        this.updateHash('email')
        this.updateHash('pec')
      })
    }
  }

  async componentDidMount() {
    let { user_id = null } = this.props
    await this.props.dispatch(getUserSettings(user_id))
    this.resetState()
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.user_id !== this.props.user_id) {
      await this.props.dispatch(getUserSettings(this.props.user_id))
    }
    if (prevProps.user_settings !== this.props.user_settings) {
      this.resetState(true)
    }
  }

  handleDeleteSetting = id => async () => {
    showConfirmDialog(
      'Elimina configurazione',
      'Per procedere alla cancellazione click su Elimina',
      async () => {
        await this.props.dispatch(deleteSetting(id))
      },
      () => {},
      'primary',
      <Trans i18nKey="common.delete_button">Elimina</Trans>
    )
  }

  resetError = name => {
    let errors = { ...this.props.errors }
    if (errors[name]) {
      delete errors[name]
      this.props.dispatch(updateErrors(errors))
    }
  }
  handleChange = ({ target: { name, value } }) => {
    let { activeTab } = this.state
    this.resetError(name)
    let newState = this.state[activeTab]
    newState[name] = value
    this.setState({ [activeTab]: { ...newState } })
  }
  handleCheck = ({ target: { name } }) => {
    let { activeTab } = this.state
    let newState = this.state[activeTab]
    let { ssl, tls } = this.state[activeTab]
    this.setState({ [activeTab]: { ...newState, ssl: name === 'ssl' && !ssl, tls: name === 'tls' && !tls } })
  }
  handleAuthenticationChange = selected => {
    let { activeTab } = this.state
    let newState = this.state[activeTab]
    newState.authentication = selected?.value || ''
    this.setState({ [activeTab]: { ...newState } })
  }
  handleStartTLS = () => {
    let { activeTab } = this.state
    let newState = this.state[activeTab]
    newState.enable_starttls_auto = !newState.enable_starttls_auto
    this.setState({ [activeTab]: { ...newState } })
  }

  saveUserSettings = async () => {
    let { user_id } = this.props
    let { email, pec, activeTab } = this.state
    let { type: type1, ...restEmail } = email
    let { type: type2, ...restPec } = pec
    let errors = validateFields(activeTab === 'email' ? { ...email } : { ...pec })
    if (Object.keys(errors).length) {
      await this.props.dispatch(updateErrors(errors))
    } else {
      if (activeTab === 'email' && email.address !== '')
        await this.props.dispatch(saveUserSettings({ data: restEmail, user_id }))
      if (activeTab === 'pec' && pec.address !== '')
        await this.props.dispatch(saveUserSettings({ data: restPec, user_id }))
    }
  }

  renderEmailForm = config => {
    let { user_name, password, domain, address, port, from, authentication, enable_starttls_auto, ssl, tls } =
      this.state[config]
    let { errors } = this.props

    return (
      <>
        <div className="d-flex mt-4 align-items-center">
          <div className="col-3">
            <Form.Label> Utente </Form.Label>
            <span> *</span>
          </div>
          <Form.Group className="d-flex flex-column flex-fill">
            <Form.Control
              name="user_name"
              placeholder={'test@email.it'}
              value={user_name}
              onChange={this.handleChange}
              isInvalid={'user_name' in errors}
            />
            <Form.Control.Feedback type="invalid">{errors.user_name}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex mt-3 align-items-center">
          <div className="col-3">
            <Form.Label>Password</Form.Label>
            <span> *</span>
          </div>
          <Form.Group className="d-flex flex-column flex-fill">
            <Form.Control
              name="password"
              placeholder={'********'}
              type="password"
              value={password}
              onChange={this.handleChange}
              isInvalid={'password' in errors}
            />
            <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex mt-4 align-items-center">
          <div className="col-3">
            <Form.Label>Dominio</Form.Label>
            <span> *</span>
          </div>
          <Form.Group className="d-flex flex-column flex-fill">
            <Form.Control
              name="domain"
              placeholder={'conhive.it'}
              value={domain}
              onChange={this.handleChange}
              isInvalid={'domain' in errors}
            />
            <Form.Control.Feedback type="invalid">{errors.domain}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex mt-3 align-items-center">
          <div className="col-3">
            <Form.Label>Host SMTP</Form.Label>
            <span> *</span>
          </div>
          <Form.Group className="d-flex flex-column flex-fill">
            <Form.Control
              name="address"
              placeholder={'smtp.myprovider.it'}
              value={address}
              onChange={this.handleChange}
              isInvalid={'address' in errors}
            />
            <Form.Control.Feedback type="invalid">{errors.address}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex mt-3 align-items-center">
          <div className="col-3">
            <Form.Label>Porta SMTP</Form.Label>
            <span> *</span>
          </div>
          <Form.Group className="d-flex flex-column flex-fill">
            <Form.Control
              name="port"
              placeholder={'465'}
              value={port}
              onChange={this.handleChange}
              isInvalid={'port' in errors}
            />
            <Form.Control.Feedback type="invalid">{errors.port}</Form.Control.Feedback>
          </Form.Group>
        </div>
        <div className="d-flex mt-3 align-items-center">
          <div className="col-3">
            <Form.Label>Usa protocollo</Form.Label>
            <span> *</span>
          </div>
          <div className="d-flex align-items-center">
            <Form.Check type="switch" id="protocol1" name="ssl" label="SSL" checked={ssl} onChange={this.handleCheck} />
            <Form.Check
              className="ms-3"
              type="switch"
              id="protocol2"
              name="tls"
              label="TLS"
              checked={tls}
              onChange={this.handleCheck}
            />
          </div>
        </div>
        <div className="d-flex mt-3 align-items-center">
          <div className="col-3">
            <Form.Label>Autenticazione</Form.Label>
            <span> *</span>
          </div>
          <div className="d-flex align-items-center" style={{ minWidth: '10rem' }}>
            <StyledSelect
              placeholder="..."
              value={smtp_authentications.find(tp => tp.value === authentication?.toString()) || ''}
              options={smtp_authentications}
              onChange={this.handleAuthenticationChange}
            />
          </div>
        </div>
        <div className="d-flex mt-3 align-items-center">
          <div className="col-3">
            <Form.Label>Abilita StartTLS</Form.Label>
          </div>
          <div className="d-flex align-items-center">
            <Form.Check
              type="checkbox"
              id="enable_starttls_auto"
              name="enable_starttls_auto"
              checked={enable_starttls_auto}
              onChange={this.handleStartTLS}
            />
          </div>
        </div>
        <div className="d-flex mt-3 align-items-center">
          <div className="col-3">
            <Form.Label> Inviare da </Form.Label>
          </div>
          <Form.Group className="d-flex flex-column flex-fill">
            <Form.Control
              name="from"
              placeholder={''}
              value={from}
              onChange={this.handleChange}
              isInvalid={'from' in errors}
            />
            <Form.Control.Feedback type="invalid">{errors.from}</Form.Control.Feedback>
          </Form.Group>
        </div>
      </>
    )
  }

  render() {
    let { show } = this.props
    let { email, pec, email_hash, pec_hash, activeTab } = this.state
    let modified =
      (activeTab === 'email' && hash(email) !== email_hash) || (activeTab === 'pec' && hash(pec) !== pec_hash)

    return (
      <>
        <Modal backdrop="static" centered show={show} onHide={this.props.onCloseModal} size="xl">
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title>
              <h4 className="text-primary p-1">
                <Trans i18nKey="settings.modal.title">Impostazioni</Trans>
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tab.Container
              id="left-tabs-example"
              defaultActiveKey={activeTab}
              onSelect={activeTab => this.setState({ activeTab })}>
              <Row>
                <Col sm={3} className="border-end">
                  <Nav variant="pills" className="flex-column">
                    <Nav.Item>
                      <Nav.Link
                        eventKey="email"
                        className="d-flex p-2 justify-content-between align-items-center"
                        href="#">
                        Sender Email
                        {email.id && <i className="fa fa-check" />}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link
                        eventKey="pec"
                        className="d-flex p-2 justify-content-between align-items-center"
                        href="#">
                        Sender PEC
                        {pec.id && <i className="fa fa-check" />}
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </Col>
                <Col sm={9}>
                  <Tab.Content>
                    <Tab.Pane eventKey="email">
                      <div className="h5 d-flex justify-content-between">
                        Configura sender Email
                        {email.id && (
                          <Button variant="secondary" size="sm" onClick={this.handleDeleteSetting(email.id)}>
                            <i className="fa fa-trash" />
                          </Button>
                        )}
                      </div>
                      {activeTab === 'email' && this.renderEmailForm('email')}
                    </Tab.Pane>
                    <Tab.Pane eventKey="pec">
                      <div className="h5 d-flex justify-content-between">
                        Configura sender PEC
                        {pec.id && (
                          <Button variant="secondary" size="sm" onClick={this.handleDeleteSetting(pec.id)}>
                            <i className="fa fa-trash" />
                          </Button>
                        )}
                      </div>
                      {activeTab === 'pec' && this.renderEmailForm('pec')}
                    </Tab.Pane>
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.props.onCloseModal} className="me-auto">
              <Trans i18nKey="common.cancel_button">Annulla</Trans>
            </Button>
            <Button variant="primary" onClick={this.saveUserSettings} disabled={!modified}>
              <Trans i18nKey="common.cancel_button">Salva</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }
}

export default connect(mstp)(ModalSettings)
