import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'lib/axios'
import cookies from 'browser-cookies'

import config from 'config'
import { updateUserinfo, setCurrentUser } from './userinfo'
import { getRoles } from './users'

export const initialState = {
  email: '',
  loading: false,
  status: '',

  message_type: 'success',
  message: '',
}
export const logout = createAsyncThunk('login/logout', async (_, thunkApi) => {
  try {
    await axios({
      url: `${config.SERVER_API_URL}/users/logout`,
      method: 'delete',
    })
    cookies.erase('conhive-auth')
    thunkApi.dispatch(updateUserinfo({ id: null, email: '', name: '', surname: '', role_id: 0 }))
    return { status: 'login' }
  } catch (err) {
    return thunkApi.rejectWithValue({})
  }
})

const login = async postData => {
  try {
    let url = `${config.SERVER_API_URL}/users/login`
    let data = { user: { ...postData } }

    if (postData.email === 'email') {
      url = `${config.SERVER_API_URL}/users/login.json`
      data = {}
    }
    let response = await axios({
      url,
      method: 'post',
      data,
    })
    let { id, attributes, relationships } = response.data.data
    let { email, name, surname } = attributes
    let role_id = relationships?.role?.data?.id
    return { id, email, name, surname, role_id }
  } catch (err) {
    throw err
  }
}

export const refreshToken = createAsyncThunk('login/refreshToken', async (_, thunkApi) => {
  try {
    let { id, email, name, surname, role_id } = await login({ email: 'email', password: 'password' })
    thunkApi.dispatch(updateUserinfo({ id, email, name, surname, role_id }))
    return { email, loading: false, status: 'refresh' }
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    return thunkApi.rejectWithValue({
      loading: false,
      message_type: 'danger',
      message: 'Sessione scaduta',
      status: 'login',
    })
  }
})

export const tryLogin = createAsyncThunk('login/tryLogin', async (data, thunkApi) => {
  try {
    thunkApi.dispatch(loginSlice.actions.resetMessage())
    thunkApi.dispatch(loginSlice.actions.setLoading(true))
    let { id, email, name, surname, role_id } = await login(data)
    thunkApi.dispatch(updateUserinfo({ id, email, name, surname, role_id }))
    thunkApi.dispatch(setCurrentUser(id))
    thunkApi.dispatch(getRoles())
    return { email, loading: false, status: 'refresh' }
  } catch (err) {
    if (err?.message === 'canceled') return thunkApi.rejectWithValue()
    if (err?.response?.status === 401) {
      return thunkApi.rejectWithValue({
        loading: false,
        message_type: 'danger',
        message: 'Username o password errati',
        status: 'login',
      })
    }
    return thunkApi.rejectWithValue({
      loading: false,
      message_type: 'warning',
      message: 'Impossibile contattare il server. Sistemi in manutenzione',
      status: 'login',
    })
  }
})

export const loginSlice = createSlice({
  name: 'login',
  initialState,

  reducers: {
    resetLoginData: () => {
      return initialState
    },
    setLoading: (state, action) => {
      state.loading = action.payload
    },
    resetMessage: state => {
      state.message = ''
      state.message_type = 'success'
    },
  },
  extraReducers: {
    [tryLogin.fulfilled]: (state, action) => {
      let { email, loading, status } = action.payload
      state.loading = loading
      state.email = email
      state.status = status
    },
    [tryLogin.rejected]: (state, action) => {
      let { message, message_type, loading } = action.payload
      state.message = message
      state.message_type = message_type
      state.loading = loading
      state.status = 'login'
    },
    [refreshToken.fulfilled]: (state, action) => {
      let { email, loading, status } = action.payload
      state.loading = loading
      state.email = email
      state.status = status
    },
    [refreshToken.rejected]: (state, action) => {
      try {
        let { message, message_type, loading } = action.payload
        state.message = message
        state.message_type = message_type
        state.loading = loading
        state.status = 'login'
      } catch (err) {}
    },
    [logout]: (state, action) => {
      state.status = action.payload.status
    },
  },
})

export const { resetLoginData, setLoading, resetMessage } = loginSlice.actions
export default loginSlice.reducer
