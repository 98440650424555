import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { Trans } from 'react-i18next'
import { ListGroup, Fade } from 'react-bootstrap'

import { isDev, isCondominiaAdmin } from 'lib/utils'
import WithCapabilities from 'enhancers/with_capabilities'
import withJWTSession from 'enhancers/jwt_session'

import 'scss/navbar.css'

const mstp = state => {
  let { roles } = state.usersData
  let { info } = state.userInfo
  return {
    user: info,
    opened: state.common.fadebar,
    isCondominiaAdmin: isCondominiaAdmin(state.userInfo?.role_id, roles),
  }
}

class Fadebar extends React.Component {
  render() {
    let { user, router, isCondominiaAdmin } = this.props
    let {
      location: { pathname },
    } = router

    let postalization_enabled = user?.enabled_features?.postalization === true
    if (!isCondominiaAdmin) postalization_enabled = true

    return (
      <>
        <div className="icon-menu">
          <ListGroup className="gap-3 text-center pt-3">
            <Link to="/">
              <ListGroup.Item
                action
                variant="light"
                style={{ padding: '0.75rem', backgroundColor: pathname === '/' ? 'lightgrey' : '' }}>
                <i className="fas fa-home" title="Home"></i>
              </ListGroup.Item>
            </Link>
            <Link to="/condominia">
              <ListGroup.Item
                action
                variant="light"
                style={{ padding: '0.75rem', backgroundColor: pathname === '/condominia' ? 'lightgrey' : '' }}>
                <i className="fas fa-building" title="Lista condomini"></i>
              </ListGroup.Item>
            </Link>
            <Link to="/contracts">
              <ListGroup.Item
                action
                variant="light"
                style={{ padding: '0.75rem', backgroundColor: pathname === '/contracts' ? 'lightgrey' : '' }}>
                <i className="fas fa-file-signature" title="Lista contratti"></i>
              </ListGroup.Item>
            </Link>
            <Link to="/transfers">
              <ListGroup.Item
                action
                variant="light"
                style={{ padding: '0.75rem', backgroundColor: pathname === '/transfers' ? 'lightgrey' : '' }}>
                <i className="fas fa-random" title="Lista trasferimenti"></i>
              </ListGroup.Item>
            </Link>
            {postalization_enabled === true && (
              <Link to="/mailing">
                <ListGroup.Item
                  action
                  variant="light"
                  style={{ padding: '0.75rem', backgroundColor: pathname === '/mailing' ? 'lightgrey' : '' }}>
                  <i className="fas fa-envelope-open-text" title="Postalizzazione"></i>
                </ListGroup.Item>
              </Link>
            )}
            {isDev() === true && (
              <>
                <Link to="/assignments">
                  <ListGroup.Item action variant="light" style={{ padding: '0.75rem' }}>
                    <i className="fas fa-list" title="Lista incarichi"></i>
                  </ListGroup.Item>
                </Link>
                <Link to="/stats">
                  <ListGroup.Item action variant="light" style={{ padding: '0.75rem' }}>
                    <i className="fas fa-chart-line" title="Statistiche"></i>
                  </ListGroup.Item>
                </Link>
              </>
            )}
            <Link to="/users">
              <ListGroup.Item
                action
                variant="light"
                style={{ padding: '0.75rem', backgroundColor: pathname === '/users' ? 'lightgrey' : '' }}>
                <i className="fas fa-users" title="Utenti"></i>
              </ListGroup.Item>
            </Link>
          </ListGroup>
        </div>
        <div className={`container-fade-menu ${this.props.opened ? 'zi-2' : ''}`}>
          <Fade className="fade-menu zi-1" in={this.props.opened} dimension="width">
            <ListGroup className="gap-3 pt-3">
              <Link to="/">
                <ListGroup.Item
                  variant="light"
                  className="text-uppercase"
                  style={{ padding: '0.75rem', backgroundColor: pathname === '/' ? 'lightgrey' : '' }}>
                  <Trans i18nKey="pages.condominia.title">Home</Trans>
                </ListGroup.Item>
              </Link>
              <Link to="/condominia">
                <ListGroup.Item
                  variant="light"
                  className="text-uppercase"
                  style={{ padding: '0.75rem', backgroundColor: pathname === '/condominia' ? 'lightgrey' : '' }}>
                  <Trans i18nKey="pages.condominia.title">Lista condomini</Trans>
                </ListGroup.Item>
              </Link>
              {isDev() === true && (
                <Link to="/economic_summary">
                  <ListGroup.Item variant="light" className="text-uppercase" style={{ padding: '0.75rem' }}>
                    <Trans i18nKey="pages.economic_summary.title">Riepilogo economico</Trans>
                  </ListGroup.Item>
                </Link>
              )}
              <Link to="/contracts">
                <ListGroup.Item
                  variant="light"
                  className="text-uppercase"
                  style={{ padding: '0.75rem', backgroundColor: pathname === '/contracts' ? 'lightgrey' : '' }}>
                  <Trans i18nKey="pages.contracts.title">Lista contratti</Trans>
                </ListGroup.Item>
              </Link>
              <Link to="/transfers">
                <ListGroup.Item
                  variant="light"
                  className="text-uppercase"
                  style={{ padding: '0.75rem', backgroundColor: pathname === '/transfers' ? 'lightgrey' : '' }}>
                  <Trans i18nKey="pages.transfers.title">Lista trasferimenti</Trans>
                </ListGroup.Item>
              </Link>
              <Link to="/mailing">
                <ListGroup.Item variant="light" className="text-uppercase" style={{ padding: '0.75rem' }}>
                  <Trans i18nKey="pages.mailing.title">Postalizzazione</Trans>
                </ListGroup.Item>
              </Link>
              {isDev() === true && (
                <>
                  <Link to="/assignments">
                    <ListGroup.Item variant="light" className="text-uppercase" style={{ padding: '0.75rem' }}>
                      <Trans i18nKey="pages.assignments.title">Lista incarichi</Trans>
                    </ListGroup.Item>
                  </Link>
                  <Link to="/stats">
                    <ListGroup.Item variant="light" className="text-uppercase" style={{ padding: '0.75rem' }}>
                      <Trans i18nKey="pages.stats.title">Statistiche</Trans>
                    </ListGroup.Item>
                  </Link>
                </>
              )}
              <Link to="/users">
                <ListGroup.Item
                  variant="light"
                  className="text-uppercase"
                  style={{ padding: '0.75rem', backgroundColor: pathname === '/users' ? 'lightgrey' : '' }}>
                  <Trans i18nKey="pages.users.title">Utenti</Trans>
                </ListGroup.Item>
              </Link>
            </ListGroup>
          </Fade>
        </div>
      </>
    )
  }
}

export default connect(mstp)(withJWTSession(Fadebar))
