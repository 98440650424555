import React from 'react'

const labels = ['Nuova postalizzazione', 'Seleziona destinatari', 'Imposta tipologia di invio', 'Finalizza']
class Breadcrumbs extends React.PureComponent {
  render() {
    let label = labels.filter((v, idx) => idx <= this.props.page).join(' > ')
    return (
      <div className="fs-2 text-secondary mt-0" style={{ fontStyle: 'italic' }}>
        {label}
      </div>
    )
  }
}

export default Breadcrumbs
