import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import { Spinner, OverlayTrigger, Tooltip, Button, Form } from 'react-bootstrap'
import i18next from 'i18next'
import 'scss/assignments.css'

import { confirmCondominia } from 'store/assignments/condominia'
import { deleteCondominium, getCondominia, getCondominium, toggleShowAddCondominia } from 'store/condominia'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import ActionsDropdownSplitted from 'components/system_wide/actions_dropdown_splitted'
import { isAdmin as _isAdmin } from 'lib/utils'

const { t } = i18next
const mstp = state => {
  let { condominia, loading, current_page, pages } = state.condominia
  let { services } = state.assignments.services
  let { id: user_id, role_id } = state.userInfo
  let { roles } = state.usersData
  let { strings: trans } = state.translations
  let tServices = trans?.tables?.services ?? {}
  let isAdmin = _isAdmin(role_id, roles)
  return {
    services,
    condominia,
    user_id,
    role_id,
    trans,
    tServices,
    loading,
    current_page,
    pages,
    isAdmin,
  }
}

class CondominiaListTable extends React.Component {
  static defaultProps = {}

  componentDidMount = async () => {}

  state = {
    hide_columns: true,
  }

  onHandleEdit = async condominium_id => {
    await this.props.dispatch(getCondominium({ condominium_id }))
    this.props.dispatch(toggleShowAddCondominia())
  }

  onHandleDeleteCondominium = async (id, name, user_id) => {
    showConfirmDialog(
      <Trans i18nKey="assignments.delete_condominium_modal.modal_title">Elimina condominio</Trans>,
      <Trans
        i18nKey="assignments.delete_condominium_modal.modal_msg"
        values={{ name }}
        defaults="Per procedere alla cancellazione di <1>{{ name }}</1> click su Elimina"
        components={{ 1: <b /> }}
      />,
      async () => {
        await this.props.dispatch(deleteCondominium({ condominium_id: id, user_id }))
      },
      () => {},
      'primary',
      <Trans i18nKey="common.delete_button">Elimina</Trans>
    )
  }

  onCloseCondominia = async () => {
    await this.props.dispatch(getCondominia(this.props.current_page))
  }

  onConfirmCondominia = async ({ target: { value } }) => {
    await this.props.dispatch(confirmCondominia({ assignment_id: this.props.current_assignment.id, action: value }))
  }

  onHideColumns = () => this.setState({ hide_columns: !this.state.hide_columns })

  selectCheckboxRender = rowData => {
    let { selected_condominia } = this.props
    return (
      <Form.Check
        type="checkbox"
        className="py-1 me-4 ms-3"
        value={rowData.id}
        checked={selected_condominia.includes(rowData.id.toString())}
        onChange={this.props.toggleCondominium}
      />
    )
  }

  renderCheckbox = () => {
    let { selected_condominia, type_selected, condominia } = this.props
    if (type_selected === 'single') return ''
    let all_selected = condominia.every(u => selected_condominia.includes(u.id.toString()))
    return (
      <div className="ms-3 m-015">
        <Button onClick={this.props.toggleAllCondominia} variant="secondary" className="no-padding-button">
          <i className={`far ${all_selected ? 'fa-check-square' : 'fa-square'}`} style={{ fontSize: '1.3rem' }}></i>
        </Button>
      </div>
    )
  }

  handleNewMailSender = id => {
    this.props.navigate(`/mailing/edit/0/${id}`)
  }

  actions = [
    {
      icon: 'fa-eye',
      text: t('actions_dropdown.show_item', 'Visualizza'),
      onClick: ({ id }) => this.props.onShowCondominium(id),
    },
    {
      icon: 'fa-edit',
      text: t('actions_dropdown.edit_item', 'Modifica'),
      disabled: rowData => rowData.id === this.props.own_user_id,
      onClick: ({ id }) => this.onHandleEdit(id),
    },
    // {
    //   icon: 'fa-random',
    //   text: t('actions_dropdown.transfer_item', 'Trasferisci'),
    //   onClick: ({ id }) => this.onHandleTransfer(id),
    // },
    {
      icon: 'fa-envelope-open-text',
      text: t('actions_dropdown.mailing_item', 'Nuova postalizzazione'),
      onClick: ({ id }) => this.handleNewMailSender(id),
    },
    {
      icon: 'fa-trash',
      text: t('actions_dropdown.delete_item', 'Elimina'),
      onClick: ({ id, name, user_id }) => this.onHandleDeleteCondominium(id, name, user_id),
    },
  ]

  handleSelection = () => {
    this.props.handleSelection()
  }

  render() {
    let { condominia, services, isAdmin, loading, tServices, type_selected } = this.props
    let { hide_columns } = this.state

    let condominia_services = condominia.reduce((acc, c) => {
      return [...new Set([...acc, ...(c.services ?? [])?.map(s => s.id)])]
    }, [])
    let selected_services = services.filter(s => condominia_services.includes(s.value))

    return (
      <>
        <div className="d-flex flex-column flex-fill w-100 overflow-auto border" style={{ backgroundColor: '#f4f4f4' }}>
          <div
            className="d-flex flex-row w-100"
            style={{
              color: 'white',
              position: 'sticky',
              top: 0,
              zIndex: 1,
              backgroundColor: '#6C757D',
            }}>
            <div
              className="d-flex align-middle table-column fw-bold z-1"
              style={{
                minWidth: '27rem',
                position: 'sticky',
                left: 0,
                backgroundColor: '#6C757D',
              }}>
              {type_selected === 'multiple' && this.renderCheckbox()}
              <div className="w-100 text-center">Condominio</div>
              <div className="text-end" onClick={this.onHideColumns} role="button">
                {hide_columns ? (
                  <i className="fas fa-caret-square-right" />
                ) : (
                  <i className="fas fa-caret-square-left" />
                )}
              </div>
            </div>
            {isAdmin && (
              <div
                className={`align-middle table-column fw-bold`}
                style={{
                  minWidth: '24rem',
                  width: '24rem',
                  backgroundColor: '#6C757D',
                }}>
                <Trans i18nKey="condominia.administrator">Amministratore</Trans>
              </div>
            )}
            <div
              className={`align-middle table-column fw-bold ${hide_columns ? 'd-none' : ''}`}
              style={{ minWidth: '25rem', backgroundColor: '#6C757D' }}>
              <Trans i18nKey="assignments.services.number">Indirizzo</Trans>
            </div>
            <div
              className={`align-middle table-column fw-bold ${hide_columns ? 'd-none' : ''} `}
              style={{ minWidth: '20rem', backgroundColor: '#6C757D' }}>
              <Trans i18nKey="assignments.services.cost">Codice fiscale</Trans>
            </div>
            <div
              className={`align-middle table-column fw-bold ${hide_columns ? 'd-none' : ''}`}
              style={{ minWidth: '25rem', backgroundColor: '#6C757D' }}>
              <Trans i18nKey="assignments.services.commission">Iban</Trans>
            </div>
            {selected_services.map((s, idx) => {
              let target = React.createRef()
              return (
                <div
                  key={s.value}
                  className="align-center align-middle table-column fw-bold"
                  style={{
                    width: '12rem',
                    minWidth: '12rem',
                    backgroundColor: '#6C757D',
                  }}>
                  <OverlayTrigger
                    key={s.value}
                    trigger={['hover', 'click']}
                    overlay={<Tooltip>{tServices?.[s.codename] ?? s.label}</Tooltip>}>
                    <div className="d-flex justify-content-center">
                      <div style={{ width: '1.3rem' }} className="d-flex align-items-center">
                        <i ref={target} className={`${s.icon_classes} m-auto`} />
                      </div>
                      <div className="ps-2 text-break">{tServices?.[s.codename] ?? s.label}</div>
                    </div>
                  </OverlayTrigger>
                </div>
              )
            })}
          </div>
          {loading && (
            <div
              className="d-flex w-100 vh-94 justify-content-center"
              style={{ position: 'absolute', backgroundColor: '#cfcfcf90', zIndex: '99999' }}>
              <div className="w-100 m-auto text-center">
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </div>
            </div>
          )}
          {condominia.map((c, idx) => {
            let backgroundColor = idx % 2 === 1 ? 'white' : '#f4f4f4'
            let user_name = c.user?.tax_datum?.business_name ?? `${c.user?.name ?? 'CEDUTO'} ${c.user?.surname ?? ''}`
            return (
              <div className="d-flex flex-row" key={`cond${c.id}`} style={{ backgroundColor }}>
                <div
                  className="d-flex table-item align-items-center"
                  style={{ minWidth: '27rem', maxWidth: '27rem', backgroundColor }}>
                  {type_selected === 'single' && (
                    <ActionsDropdownSplitted
                      className="me-3"
                      rowData={c}
                      actions={this.actions}
                      onClickMaster={() => this.props.onShowCondominium(c.id)}
                    />
                  )}
                  {type_selected === 'multiple' && this.selectCheckboxRender(c)}
                  {isAdmin && <div style={{ minWidth: '2.8rem' }}>{c.id} - </div>}
                  <div className="text-wrap-ow">{c.name}</div>
                </div>
                {isAdmin && (
                  <div
                    className="d-flex text-break table-item align-items-center"
                    style={{
                      minWidth: '24rem',
                      width: '24rem',
                      backgroundColor,
                      ...(c.user?.name ? {} : { color: 'orange', fontWeight: 'bold' }),
                    }}>
                    {c?.user?.deactivated === true && (
                      <i className="fa fa-ban me-3 text-warning " aria-hidden="true" title="Disabilitato" />
                    )}
                    {user_name}
                  </div>
                )}
                <div
                  className={`${hide_columns ? 'd-none' : 'd-flex'} table-item align-items-center`}
                  style={{ minWidth: '25rem', backgroundColor }}>
                  {c.cap} - {c.city?.name} - {c.street}
                </div>
                <div
                  className={`${hide_columns ? 'd-none' : 'd-flex'} table-item align-items-center`}
                  style={{ minWidth: '20rem', backgroundColor }}>
                  {c.fiscal_code}
                </div>
                <div
                  className={`${hide_columns ? 'd-none' : 'd-flex'} table-item align-items-center`}
                  style={{ minWidth: '25rem', backgroundColor }}>
                  {c.iban}
                </div>
                {selected_services.map((s, idx) => {
                  // let service = this.props.services.find(ser => ser.value === s.value)
                  let service_option = {}
                  // let service_option = service.service_options.find(
                  //   so => parseInt(so.value) === parseInt(c.services_options[service.value])
                  // )
                  return (
                    <div
                      key={`value-${c.id}-${s.value}`}
                      className="d-flex justify-content-center align-items-center"
                      style={{
                        width: '12rem',
                        minWidth: '12rem',
                        backgroundColor,
                      }}>
                      {(c.services ?? []).map(sc => sc.id).includes(s.value) ? (
                        service_option?.label ? (
                          service_option.label
                        ) : (
                          <i className="fas fa-check" />
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  )
                })}
                <div className="flex-fill"></div>
              </div>
            )
          })}
          {condominia.length === 0 && (
            <div className="d-flex m-auto">
              <div className="border bg-white p-2 fw-bold">Nessun record trovato</div>
            </div>
          )}
        </div>
      </>
    )
  }
}

export default connect(mstp)(CondominiaListTable)
