import React from 'react'
import { Form, Button, Modal, Table } from 'react-bootstrap'
import { Trans } from 'react-i18next'
import { printOptionsLabels } from 'lib/utils'

class AddAttachment extends React.Component {
  state = { show_modal: false, selected_target: 'all', selected_recipients: [] }

  closeModal = () => this.setState({ show_modal: false })

  uploadFile = async () => {
    let { selected_recipients } = this.state
    await this.props.uploadFile(selected_recipients)
    this.closeModal()
  }

  changeTarget = value => () => this.setState({ selected_target: value })

  checkAllRecipients = () => {
    let { recipients } = this.props
    let { filter_recipient, selected_recipients } = this.state
    let reps = [...recipients]
    if (filter_recipient !== '') {
      reps = reps.filter(
        e =>
          e.name.toLowerCase().includes(filter_recipient) ||
          e.fiscal_identifier.toLowerCase().includes(filter_recipient) ||
          e.address.street.toLowerCase().includes(filter_recipient)
      )
    }

    if (selected_recipients.length < reps.length) {
      this.setState({ selected_recipients: reps.map(e => e.id) })
    }
    if (selected_recipients.length === reps.length) {
      this.setState({ selected_recipients: [] })
    }
  }

  checkRecipients = id => () => {
    let { selected_recipients } = this.state
    if (selected_recipients.includes(id)) {
      selected_recipients = selected_recipients.filter(s => s !== id)
    } else {
      selected_recipients.push(id)
    }
    this.setState({ selected_recipients })
  }

  render() {
    let { show_modal, selected_target, selected_recipients } = this.state
    let {
      selectedFile,
      selectedFileLabel,
      errors,
      FileUploadHandler,
      setUploadForm,
      disabled,
      print_options,
      poTrans,
      selected_options,
      checkOptions,
      recipients,
      selectRecipients,
    } = this.props

    return (
      <>
        <Modal show={show_modal} size="lg">
          <Modal.Header>
            <h5>Aggiungi allegato {this.props.delegate ? 'con i destinatari di postalizzazione' : ''}</h5>
          </Modal.Header>
          <Modal.Body>
            <div className="container">
              <div className="row">
                <div className="text-muted modal-title">
                  <Trans i18nKey="mailing.modal_new_mailing.verbal">Seleziona un file (Dimensione massima 10Mb):</Trans>
                </div>
                <div className="row d-flex">
                  <div className="col-9">
                    <Form.Control
                      type="file"
                      id="upload"
                      key="upload_form"
                      label={selectedFileLabel}
                      ref={form => setUploadForm(form)}
                      onChange={FileUploadHandler}
                      disabled={disabled}
                      isInvalid={'selectedFile' in errors}
                    />
                    <Form.Control.Feedback type="invalid">{errors.selectedFile}</Form.Control.Feedback>
                  </div>
                </div>
              </div>
              {!this.props.delegate && (
                <div>
                  <div className="mb-2 mt-4 text-muted"> Opzioni di stampa per il singolo file: </div>
                  <div className="d-flex flex-column flex-wrap gap-2">
                    {Object.keys(print_options).map(o => (
                      <div className="d-flex ms-3" key={o}>
                        <div className="me-3">{printOptionsLabels?.[o] ?? o}:</div>
                        {(print_options?.[o] ?? []).map(r => (
                          <Form.Check
                            key={`print-${r.id}`}
                            inline
                            label={poTrans?.[o]?.[r.value] ?? r.value}
                            name={`selected_options[${o}]`}
                            type="radio"
                            id={r.id}
                            checked={(selected_options?.[o] ?? []).includes(r.id)}
                            onChange={checkOptions(o, r.id)}
                            disabled={disabled}
                          />
                        ))}
                      </div>
                    ))}
                  </div>
                  {selectRecipients === true && (
                    <div className="d-flex mt-3">
                      <div className="me-3 mb-3 text-muted">Invia a:</div>
                      <Form.Check
                        key={`send-all`}
                        inline
                        label={'Tutti'}
                        name="selected_target"
                        type="radio"
                        id={'all'}
                        checked={selected_target === 'all'}
                        onChange={this.changeTarget('all')}
                        disabled={disabled}
                      />
                      <Form.Check
                        key={`send-only`}
                        inline
                        label={'Solo selezionati'}
                        name="selected_target"
                        type="radio"
                        id={'only'}
                        checked={selected_target === 'only'}
                        onChange={this.changeTarget('only')}
                        disabled={disabled}
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
            {selectRecipients === true && selected_target === 'only' && (
              <Table striped bordered hover size="sm">
                <thead style={{ position: 'sticky', top: 0, background: 'white', width: '101%' }}>
                  <tr>
                    <th className="border" style={{ width: '2rem', textAlign: 'center' }}>
                      <Form.Check
                        type="checkbox"
                        onChange={this.checkAllRecipients}
                        disabled={recipients.length === 0 || disabled}
                        checked={selected_recipients.length > 0 && selected_recipients.length === recipients.length}
                      />
                    </th>
                    <th className="border">Soggetto</th>
                    <th className="border">Indirizzo</th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: 470, overflow: 'auto' }}>
                  {recipients.map(e => {
                    return (
                      <tr key={`r-${e.id}`}>
                        <td style={{ textAlign: 'center' }}>
                          <Form.Check
                            type="checkbox"
                            onChange={this.checkRecipients(e.id)}
                            checked={selected_recipients.find(s => e.id === s)}
                            disabled={disabled}
                          />
                        </td>
                        <td>{e.name}</td>
                        <td>{e.address.street}</td>
                      </tr>
                    )
                  })}
                  {recipients.length === 0 && (
                    <tr>
                      <td colSpan="7" className="text-center pt-3 w-100">
                        Nessun elemento trovato
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            )}
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button variant="secondary" onClick={this.closeModal}>
              <i className={`fa fa-times`} /> Annulla
            </Button>
            <Button variant="primary" onClick={this.uploadFile} disabled={!selectedFile}>
              {this.props.labelIcon ? <i className={this.props.labelIcon} /> : <i className={`fa fa-upload me-2`} />}
              {this.props.label ?? 'Aggiungi'}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="d-flex align-items-center">
          {!disabled && 'selectedFile' in errors && <div className="text-danger me-3">{errors.selectedFile}</div>}
          <Button
            size={this.props.size ?? ''}
            variant="primary"
            disabled={disabled}
            className={`float-md-end ${!disabled && 'selectedFile' in errors ? 'border-danger border-2' : ''}`}
            onClick={() => this.setState({ show_modal: true })}>
            {this.props.labelIcon ? <i className={this.props.labelIcon} /> : <i className={`fa fa-upload me-2`} />}
            {this.props.label ?? 'Aggiungi'}
          </Button>
        </div>
      </>
    )
  }
}

export default AddAttachment
