import React from 'react'

class PriceList extends React.Component {
  render() {
    return (
      <div className="ms-3">
        I costi della postalizzazione possono essere consultati a questo{' '}
        <a
          href="https://hivegroup.openforce.it/document/share/257/884a42cc-af31-4b3c-96b1-92846c1663fb"
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}>
          <b>
            link <i className="fa fa-external-link-alt" />
          </b>
        </a>
      </div>
    )
  }
}

export default PriceList
