import React from 'react'
import i18next from 'i18next'
import { Route, Routes } from 'react-router-dom'

import withJWTSession from 'enhancers/jwt_session'
import Topbar from 'components/system_wide/topbar'
import Fadebar from 'components/system_wide/fadebar'
import MailingList from 'components/mailing'
import EditMailing from 'components/mailing/edit'
import SelectMailing from 'components/mailing/select_targets'
import SummaryMailing from 'components/mailing/summary'
import FinalizeMailing from 'components/mailing/finalize'

const { t } = i18next

class Mailing extends React.Component {
  render() {
    let { navigate } = this.props.router
    return (
      <div>
        <Topbar title={t('pages.mailing.title', 'Postalizzazione')} />
        <div className="d-flex vh-94">
          <div className="d-flex" style={{ backgroundColor: 'white' }}>
            <Fadebar />
          </div>
          <Routes>
            <Route path="/" element={<MailingList navigate={navigate} />} />
            <Route path="/edit/:mailer_id" element={<EditMailing navigate={navigate} />} />
            <Route path="/edit/:mailer_id/:condominium_id" element={<EditMailing navigate={navigate} />} />
            <Route path="/select_targets/:mailer_id" element={<SelectMailing navigate={navigate} />} />
            <Route path="/summary/:mailer_id" element={<SummaryMailing navigate={navigate} />} />
            <Route path="/finalize/:mailer_id" element={<FinalizeMailing navigate={navigate} />} />
          </Routes>
        </div>
      </div>
    )
  }
}

export default withJWTSession(Mailing)
