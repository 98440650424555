import i18next from 'i18next'

const { t } = i18next

const validateFields = (data, validators) => {
  let requiredFields = ['title', 'payer_id', 'payer_type', 'selectedFile', 'iban', 'sdi']

  let errors = {}

  // check required fields
  for (const field of requiredFields) {
    if (!data[field]) errors[field] = t('form.errors.required_field', 'Campo richiesto')
    if (field === 'selectedFile' && data['attachments']?.length > 0) delete errors[field]
  }

  return errors
}

export default validateFields
