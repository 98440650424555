import React from 'react'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import { Button, Modal, Alert, Spinner } from 'react-bootstrap'

import axios from 'lib/axios'
import config from 'config'
import { success, error } from '../notification'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import jsonapiToData from 'lib/jsonapi_to_data'
import FileDropzone from 'components/system_wide/file_dropzone'
import MappingForm from './mapping_form'
import ImportList from './import_list'
import StyledSelect from '../styled_select'

const { t } = i18next

const initialState = {
  errors: [],
  errors_form: {},
  result: 0,
  dimmed: false,
  showDragzone: true,
  showImportList: false,
  showLoading: false,
  import_id: null,
  status: '',
  list: [],
  user_id: '',
}

class BulkImport extends React.Component {
  static defaultProps = {
    title: 'Importazione',
    model: 'Condominium',
    className: '',
    disabled: false,
    current_user: null,
    isAdmin: false,
  }

  state = { ...initialState }

  getFileToDownload = apiUrl => {
    return axios.get(apiUrl, {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  downloadErrors = async id => {
    this.getFileToDownload(`${config.SERVER_API_URL}${this.props.importUrl}/${id}/leftovers`).then(response => {
      const type = response.headers['content-type']
      const blob = new Blob([response.data], { type: type, encoding: 'UTF-8' })
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'errors.xlsx'
      link.click()
    })
  }

  getAllImports = async () => {
    if (this.props.importUrl && this.state.showImportList === false) {
      try {
        this.setState({ showLoading: true })
        let response = await axios.get(`${config.SERVER_API_URL}${this.props.importUrl}?include=target`)
        let list = jsonapiToData(response.data)
        this.setState({ list: list.data, showImportList: true, showLoading: false })
      } catch (err) {
        this.setState({ list: [], showImportList: false, showLoading: false })
      }
    } else {
      this.setState({ list: [], showImportList: false, showLoading: false })
    }
  }

  handleShowImportList = () => {
    this.getAllImports()
  }

  checkImportStatus = async () => {
    if (this.props.importUrl) {
      this.setState({ showLoading: true })
      let response = await axios.get(
        `${config.SERVER_API_URL}${this.props.importUrl}?filter[with_current_state]=open,mapping,converted,confirmed`
      )
      let imports_list = jsonapiToData(response.data)
      let import_field = (imports_list?.data || []).filter(el => el.current_state !== 'imported')?.[0] ?? null
      if (import_field && import_field.current_state !== 'imported') {
        let { id, current_state, available_columns, columns_map } = import_field
        this.setState({ import_id: id, status: current_state, available_columns, columns_map, showDragzone: false })
      }
      this.setState({ showLoading: false })
    }
  }
  componentDidMount() {
    this.checkImportStatus()
  }

  onDrop = async file => {
    if (this.props.isAdmin && this.state.user_id === '' && !this.props.skip_user) {
      this.setState({ errors_form: { user_id: 'Campo obbligatorio' } })
    } else {
      if (/xlsx$/.test(file.name) || /xls$/.test(file.name)) {
        this.setState({ dimmed: true, filename: file.name })
        await this.fileUploader(file)
      } else {
        this.setState({
          dimmed: false,
          showDragzone: false,
          parseOk: false,
          errors: [t('system_wide.bulk_import.invalid_format_file', 'Formato del file non valido')],
        })
      }
    }
  }

  handleAdministratorChange = option => {
    let value = option ? option.value : ''
    let errors_form = this.state.errors_form
    delete errors_form['user_id']
    this.setState({ user_id: value, errors_form })
  }

  fileUploader = async file => {
    let { user_id } = this.state
    let { current_user, model, target } = this.props
    const formData = new FormData()
    formData.append('_jsonapi[model]', model)
    if (target) {
      formData.append('_jsonapi[target_id]', target)
    } else {
      formData.append('_jsonapi[target_id]', user_id ?? current_user)
    }
    formData.append('_jsonapi[document]', file)
    try {
      let response = await axios.post(`${config.SERVER_API_URL}${this.props.importUrl}`, formData)
      let imported_data = jsonapiToData(response.data)
      let { id, available_columns, columns_map } = imported_data.data[0]
      this.setState({ import_id: id, available_columns, columns_map, dimmed: false, showDragzone: false, errors: [] })
      this.checkImportStatus()
    } catch (err) {
      this.setState({
        dimmed: false,
        showDragzone: false,
        errors: [
          err?.response?.data?.errors?.title ??
            t('system_wide.bulk_import.invalid_format_file', 'Formato del file non valido'),
        ],
      })
    }
  }
  handleColumnChange = ({ column, selected }) => {
    let { columns_map } = { ...this.state }
    columns_map[column] = selected?.value ? selected.value : null
    this.setState({ columns_map })
  }

  handleAssociate = async () => {
    let { importUrl } = this.props
    let { import_id } = this.state
    try {
      let response = await axios({
        url: `${config.SERVER_API_URL}${importUrl}/${import_id}`,
        method: 'PUT',
        data: { columns_map: this.state.columns_map },
      })
      let imported_list = jsonapiToData(response.data)
      let { id: imported_id, available_columns, columns_map } = imported_list.data[0]
      this.setState({ imported_id, available_columns, columns_map, status: 'terminated' })
      success(t('system_wide.bulk_import.associate_success', 'Mapping colonne aggiornato con successo'))
      this.checkImportStatus()
    } catch (err) {
      error(t('system_wide.bulk_import.associate_error', 'Impossibile associare le colonne'))
    }
  }

  handleConfirm = async () => {
    let { importUrl } = this.props
    let { import_id } = this.state
    try {
      await axios({
        url: `${config.SERVER_API_URL}${importUrl}/${import_id}/confirm`,
        method: 'POST',
      })
      this.handleDismiss()
      success(t('system_wide.bulk_import.confirm_success', 'Importazione schedulata con successo'))
    } catch (err) {
      error(t('system_wide.bulk_import.confirm_error', `Impossibile continuare con l'importazione`))
    }
  }

  handleDeleteImport = async () => {
    let { importUrl } = this.props
    let { import_id } = this.state

    showConfirmDialog(
      <Trans i18nKey="system_wide.bulk_import.delete.modal_title">Elimina importazione</Trans>,
      <Trans
        i18nKey="system_wide.bulk_import.delete.modal_msg"
        defaults="Per procedere alla cancellazione dell'importazione schedulata clicca su Elimina"
      />,
      async () => {
        try {
          await axios({
            url: `${config.SERVER_API_URL}${importUrl}/${import_id}`,
            method: 'DELETE',
          })
          this.setState({ ...initialState })
          success(
            t(
              'system_wide.bulk_import.delete_success',
              "Eliminazione dell'importazione schedulata avvenuta con successo"
            )
          )
        } catch (err) {
          error(t('system_wide.bulk_import.delete_error', 'Impossibile eliminare importazione in attesa'))
        }
      },
      () => {},
      'danger',
      <Trans i18nKey="common.reject_button">Elimina</Trans>
    )
  }

  handleDismiss = () => {
    this.setState({ ...initialState })
    this.props.onDismiss()
  }

  render() {
    let { administrators, isAdmin } = this.props
    let {
      dimmed,
      import_id,
      available_columns,
      columns_map,
      showDragzone,
      showImportList,
      showLoading,
      errors,
      status,
      list,
      user_id,
      errors_form,
    } = this.state

    let adminOpts = administrators.map(adm => ({
      id: adm.id,
      value: adm.id,
      label: `${adm.name} ${adm.surname} - ${adm.vat_number ?? 'Nessuna P.IVA'}`,
    }))

    return (
      <Modal backdrop="static" size="lg" centered show={true} onHide={this.handleDismiss}>
        <Modal.Header>
          <Modal.Title className="d-flex align-items-center">
            {this.props.title}
            {showImportList !== true && (
              <Button className="p-2 position-absolute" style={{ right: 20 }} onClick={this.handleShowImportList}>
                <i className="fa fa-bars pe-2" />
                Elenco importazioni
              </Button>
            )}
            {showImportList === true && (
              <Button
                variant="secondary"
                className="p-2 position-absolute"
                style={{ right: 20 }}
                onClick={this.handleShowImportList}>
                <i className="fa fa-arrow-left pe-2" />
                Indietro
              </Button>
            )}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-1">
          {showDragzone && !showImportList && errors.length === 0 && showLoading === false && (
            <Alert variant="light">
              <Alert.Heading className="mb-4 h5">
                {isAdmin === true && adminOpts.length !== 0 && (
                  <div className="row mb-5">
                    <div className="col-7">
                      <div className="text-muted modal-title">Amministratore di condominio</div>
                      <StyledSelect
                        className={`${'user_id' in errors_form ? 'is-invalid' : ''}`}
                        valid={!('user_id' in errors_form)}
                        isClearable
                        placeholder={''}
                        isDisabled={false}
                        value={adminOpts.find(u => u.value === user_id)}
                        onChange={this.handleAdministratorChange}
                        options={adminOpts}
                      />
                      {errors_form.user_id && (
                        <div className="pt-2" style={{ color: 'red', fontSize: 14 }}>
                          {errors_form.user_id}
                        </div>
                      )}
                    </div>
                  </div>
                )}
                <div className="mb-3">
                  <Trans i18nKey="system_wide.bulk_import.modal_import_upload_file">
                    Carica il file excel (xlsx/xls)
                  </Trans>
                </div>
                <FileDropzone dimmed={dimmed} onDrop={this.onDrop} />
              </Alert.Heading>
            </Alert>
          )}
          {!showDragzone && !showImportList && errors.length > 0 && (
            <Alert variant="danger" className="pb-1">
              <Alert.Heading as="h5">
                <Trans i18nKey="system_wide.bulk_import.file_check_completed">Controllo del file completato</Trans>
              </Alert.Heading>
              <div className="m-2">
                <ul className="ps-4 overflow-auto" style={{ maxHeight: '10rem' }}>
                  {this.state.errors.map((e, i) => (
                    <li key={i}>{e}</li>
                  ))}
                </ul>
              </div>
            </Alert>
          )}
          {status === 'open' && !showImportList && import_id !== null && (
            <MappingForm
              model={this.props.model}
              trans={this.props.trans}
              imported_id={import_id}
              available_columns={available_columns}
              columns_map={columns_map}
              onChange={this.handleColumnChange}
            />
          )}
          {showImportList === true && <ImportList isAdmin={isAdmin} list={list} downloadErrors={this.downloadErrors} />}
          {status === 'mapping' && !showImportList && import_id !== '' && (
            <Alert variant="light">
              <Alert.Heading as="h5" className="mb-4">
                <Trans i18nKey="system_wide.bulk_import.modal_import_terminated">Per terminare</Trans>
              </Alert.Heading>
              Clicca su Conferma per terminare il processo di importazione dati.
            </Alert>
          )}
          {(status === 'converted' || status === 'confirmed') && !showImportList && import_id !== '' && (
            <Alert variant="light">
              <Alert.Heading as="h5" className="mb-4 text-center">
                <Trans i18nKey="system_wide.bulk_import.modal_import_terminated">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Importazione ancora in fase di lavorazione...</span>
                  </Spinner>
                </Trans>
              </Alert.Heading>
              <div className="fs-5 text-center">Importazione ancora in fase di lavorazione...</div>
            </Alert>
          )}
          {showLoading === true && (
            <Alert variant="light" style={{ textAlign: 'center' }}>
              <Alert.Heading as="h5" className="mb-4 mt-4">
                <Trans i18nKey="system_wide.bulk_import.modal_import_loading">
                  Recupero informazioni sulle importazioni, attendere prego.
                </Trans>
              </Alert.Heading>
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Recupero informazioni sulle importazioni, attendere prego.</span>
              </Spinner>
            </Alert>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!showImportList && (status === 'open' || status === 'mapping') && import_id !== '' && (
            <Button onClick={this.handleDeleteImport} disabled={import_id === ''} variant="danger">
              <Trans i18nKey="button_cancel">Elimina importazione</Trans>
            </Button>
          )}
          {showImportList !== true && (
            <Button className="me-auto" onClick={this.handleDismiss} variant="secondary">
              <Trans i18nKey="button_close">Chiudi</Trans>
            </Button>
          )}
          {!showImportList && status === 'open' && import_id !== '' && (
            <Button onClick={this.handleAssociate} disabled={false} variant="primary">
              <Trans i18nKey="button_update_association">Aggiorna associazioni</Trans>
            </Button>
          )}
          {!showImportList && status === 'mapping' && (
            <Button onClick={this.handleConfirm} disabled={false} variant="primary">
              <Trans i18nKey="button_confirm">Conferma</Trans>
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    )
  }
}

export default BulkImport
