import React from 'react'
import { connect } from 'react-redux'
import { Trans } from 'react-i18next'
import i18next from 'i18next'
import { Form, OverlayTrigger, Tooltip, Dropdown, Button, Spinner, ProgressBar } from 'react-bootstrap'

import { getServices } from 'store/assignments/services'
import {
  getContracts,
  filterServices,
  filterCondominia,
  filterUsers,
  filterDates,
  getContract,
  deleteContract,
  deleteAttachment,
  updateContract,
  getSuppliers,
} from 'store/contracts'
import { success, error } from 'components/system_wide/notification'
import ResponsiveTable from 'components/system_wide/responsive_table'
import { showConfirmDialog } from 'components/system_wide/confirm_dialog'
import ActionsDropdownSplitted from 'components/system_wide/actions_dropdown_splitted'
import SearchBox from 'components/system_wide/search_box'
import CheckboxFilter from 'components/system_wide/checkbox_filter'
import ExpiredList from 'components/contracts/list-expired'
import ModalUploadDocument from 'components/contracts/modal-upload-doc'
import ModalContract from 'components/contracts/modal-contract'
import StyledSelect from 'components/system_wide/styled_select'
import DatesFilter from 'components/system_wide/dates_filter'
import { renderExpireAt } from 'components/system_wide/utils'
import ColumnDropzone from 'components/system_wide/column_dropzone'
import { isAdmin as _isAdmin, getRoleIdByName } from 'lib/utils'
import WithCapabilities from 'enhancers/with_capabilities'
import axios from 'lib/axios'
import config from 'config'
import jsonapiToData from 'lib/jsonapi_to_data'

const { t } = i18next

const mstp = state => {
  let {
    contracts,
    filtered_services,
    filtered_condominia,
    filtered_users,
    total,
    expired_contracts,
    expired_total,
    edit_contract,
    filters,
    suppliers,
  } = state.contracts
  let { services } = state.assignments.services
  let { roles } = state.usersData
  let { role_id } = state.userInfo
  let { strings: trans } = state.translations
  let isAdmin = _isAdmin(role_id, roles)
  return {
    total,
    expired_total,
    roles,
    contracts,
    expired_contracts,
    services,
    filtered_services,
    filtered_condominia,
    filtered_users,
    edit_contract,
    isAdmin,
    trans,
    filters,
    suppliers,
    role_id,
  }
}

class ContractsList extends React.Component {
  static defaultProps = {
    contracts: [],
    expired_contracts: [],
    getData: () => {},
    columns: [],
    filter: null,
  }

  state = {
    show_modal_upload: false,
    show_modal_contract: false,
    filter: '',
    ready: false,
    selected_contract: '',
    activeTab: 'attivi',
    type_selected: 'single',
    selected_contracts: [],
    loading: false,
    progress: 0,
    usersOpts: [],
    condominiaOpts: [],
  }

  tableRef = null
  tableRefExpired = null

  constructor(props) {
    super(props)
    this.tableRef = React.createRef()
    this.tableRefExpired = React.createRef()
  }

  componentDidMount = async () => {
    let { role_id, roles } = this.props
    await this.props.dispatch(getServices({ module: 'Condominium' }))
    await this.props.dispatch(getSuppliers())
    if (Object.keys(this.props.roles).length > 0)
      this.actions = [...this.actions, ...this.renderRevisionItemMenu(_isAdmin(role_id, roles))]
    this.setState({ ready: true }, () => this.handleSearch())
  }

  componentWillUnmount() {
    this.props.dispatch(filterUsers([]))
    this.props.dispatch(filterCondominia([]))
  }

  componentDidUpdate(prevProps) {
    let { role_id, roles } = this.props
    if (Object.keys(prevProps.roles).length !== Object.keys(roles).length) {
      this.actions = [...this.actions, ...this.renderRevisionItemMenu(_isAdmin(role_id, roles))]
    }
  }

  renderRevisionItemMenu = isAdmin => {
    if (isAdmin) {
      return [
        {
          icon: 'fa-file-pen',
          text: t('actions_dropdown.to_review', 'Da revisionare'),
          onClick: async rowData => {
            await this.props.dispatch(updateContract({ contract_id: rowData.id, data: { need_revision: true } }))
          },
        },
      ]
    }
    return []
  }

  bulk_actions = {
    to_review: t('actions_dropdown.to_review', 'Da revisionare'),
  }

  actions = [
    {
      icon: 'fa-book',
      text: t('actions_dropdown.upload_documents', 'Documenti'),
      onClick: async rowData => {
        this.openAttachmentsModal(rowData)
      },
    },
    {
      icon: 'fa-trash',
      text: t('actions_dropdown.delete', 'Elimina'),
      onClick: ({ id, condominium_id, service }) => {
        this.deleteContract({ id, condominium_id, service })
      },
    },
  ]

  refreshAttachments = async (condominium_id, contract_id) => {
    await this.props.dispatch(getContract({ condominium_id, contract_id }))
    this.setState({ show_modal_upload: true, selected_contract: this.props.edit_contract })
  }

  deleteAttachment = async ({ attachment_id, contract_id }) => {
    await this.props.dispatch(deleteAttachment({ attachment_id, contract_id }))
  }

  openAttachmentsModal = async rowData => {
    await this.props.dispatch(getContract({ condominium_id: rowData.condominium_id, contract_id: rowData.id }))
    this.setState({ show_modal_upload: true, selected_contract: this.props.edit_contract })
  }

  openContractModal = async rowData => {
    await this.props.dispatch(getContract({ condominium_id: rowData.condominium_id, contract_id: rowData.id }))
    this.setState({ show_modal_contract: true, selected_contract: this.props.edit_contract })
  }

  toggleAllContract = () => {
    let { selected_contracts, activeTab } = this.state
    let { contracts, expired_contracts } = this.props
    let active_contracts = activeTab === 'attivi' ? contracts : expired_contracts
    let all_selected = active_contracts.every(u => selected_contracts.includes(u.id.toString()))
    let current_contracts = []

    if (all_selected) {
      current_contracts = selected_contracts.filter(su => !active_contracts.find(u => u.id === su.toString()))
    } else {
      current_contracts = new Set([...selected_contracts, ...active_contracts.map(e => e.id.toString())])
    }
    this.setState({ selected_contracts: [...current_contracts] })
  }

  toggleContract = ({ target: { value } }) => {
    let { selected_contracts } = this.state
    if (selected_contracts.includes(value)) {
      this.setState({ selected_contracts: selected_contracts.filter(e => e !== value) })
    } else {
      this.setState({ selected_contracts: [...selected_contracts, value] })
    }
  }

  handleToReviewSelected = action => () => {
    let { selected_contracts } = this.state
    showConfirmDialog(
      <Trans i18nKey="contracts.bulk_action.modal_title">Azioni massive</Trans>,
      <Trans
        i18nKey="contracts.bulk_action.modal_msg"
        values={{ count_contracts: selected_contracts.length, action_name: this.bulk_actions[action] }}
        defaults="Per procedere con l'operazione <1>{{ action_name }}</1> sui contratti selezionati <1>{{count_contracts}}</1>, cliccare su Conferma"
        components={{ 1: <b /> }}
      />,
      async () => {
        try {
          this.setState({ loading: true })
          let index = 1
          for (let contract_id of selected_contracts) {
            await this.props.dispatch(updateContract({ contract_id, data: { need_revision: true }, notify: false }))
            this.setState({ progress: Math.round((index * 100) / selected_contracts.length) })
            index++
          }
          this.setState({ loading: false })
          success(t('store.contracts.update_contract_success', 'Dati contratto aggiornati con successo'))
        } catch (err) {
          error(t('store.contracts.get_contract_error', 'Impossibile recuperare il contratto'))
        }
      },
      () => {},
      'primary',
      <Trans i18nKey="common.confirm_button">Conferma</Trans>
    )
  }

  actionsRenderer = ({ rowData }) => {
    return (
      <ActionsDropdownSplitted
        rowData={rowData}
        actions={this.actions}
        onClickMaster={() => this.openContractModal(rowData)}
      />
    )
  }

  selectCheckboxRender = ({ rowData }) => {
    let { selected_contracts } = this.state

    return (
      <Form.Check
        type="checkbox"
        className="py-1"
        value={rowData.id}
        checked={selected_contracts.includes(rowData.id.toString())}
        onChange={this.toggleContract}
      />
    )
  }

  handleSelection = () => {
    this.setState(
      {
        type_selected: this.state.type_selected === 'single' ? 'multiple' : 'single',
        selected_contracts: this.state.type_selected === 'single' ? [] : this.state.selected_contracts,
      },
      () => {
        this.handleSearch()
      }
    )
  }

  orderTotalAmountRenderer = ({ rowData: { price } }) => {
    return <div className="text-end">{price ? `${price.toFixed(2)} €` : '--'}</div>
  }

  renderService = ({ rowData: { service } }) => {
    let target = React.createRef()
    let { trans } = this.props
    let { services: tServices } = trans?.tables ?? {}
    return (
      <div className="d-flex flex-wrap gap-2">
        <OverlayTrigger
          key={service.id}
          trigger={['hover', 'click']}
          overlay={<Tooltip>{tServices?.[service?.codename] ?? service?.description}</Tooltip>}>
          <div style={{ maxWidth: '15rem' }} className="d-flex align-items-center">
            <i ref={target} style={{ width: '1rem' }} className={`${service?.icon_classes} m-auto me-3`} />
            <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {tServices?.[service?.codename] ?? service?.description}
            </div>
          </div>
        </OverlayTrigger>
      </div>
    )
  }

  renderCheckbox = () => {
    let { selected_contracts, activeTab, type_selected } = this.state
    let { contracts, expired_contracts } = this.props
    if (type_selected === 'single') return ''
    let active_contracts = activeTab === 'attivi' ? contracts : expired_contracts
    let all_selected = active_contracts.every(u => selected_contracts.includes(u.id.toString()))
    return (
      <Button onClick={this.toggleAllContract} variant="secondary" className="no-padding-button">
        <i className={`far ${all_selected ? 'fa-check-square' : 'fa-square'}`} style={{ fontSize: '1.5rem' }}></i>
      </Button>
    )
  }

  renderSupplier = ({ rowData }) => {
    let { suppliers } = this.props
    let supplier = suppliers.find(s => parseInt(s.id) === parseInt(rowData.supplier_id))
    return supplier?.name ?? ''
  }

  hasAttachmentsRenderer = ({ rowData }) => {
    return rowData.has_attachments === true ? (
      <Button onClick={() => this.openAttachmentsModal(rowData)}>
        <i className="fa fa-file-alt me-2" />
        Visualizza
      </Button>
    ) : (
      'Nessun documento'
    )
  }

  getColumn = () => {
    return [
      {
        title: this.renderCheckbox(),
        className: 'd-lg-block text-center',
        style: { width: '7%', minWidth: '7rem' },
        data: rowData => {
          return this.state.type_selected === 'single'
            ? this.actionsRenderer(rowData)
            : this.selectCheckboxRender(rowData)
        },
      },
      {
        title: t('contracts.id', 'ID'),
        className: 'd-lg-block flex-fill text-center',
        style: { width: '2%' },
        sortable: false,
        data: 'id',
      },
      {
        title: t('contracts.list.administrator', 'Amministratore'),
        className: 'd-lg-block flex-fill',
        style: { width: '15%' },
        sortable: false,
        data: ({ rowData }) => {
          if (rowData.condominium.unassigned === true) {
            return <span className="text-warning fw-bold">CEDUTO</span>
          }
          let user = rowData?.user ?? {}
          let business_name = user.tax_datum?.business_name ?? ''
          return (
            <div className="d-flex align-items-center">
              {user.deactivated === true && (
                <i className="fa fa-ban me-3 text-warning " aria-hidden="true" title="Disabilitato" />
              )}
              <div>
                {business_name !== '' ? business_name : `${rowData?.user?.name ?? ''} ${rowData?.user?.surname ?? ''}`}
              </div>
            </div>
          )
        },
      },
      {
        title: t('contracts.list.condominium_name', 'Condominio'),
        className: 'd-lg-block flex-fill',
        style: { width: '8%' },
        sortable: false,
        data: ({ rowData }) => rowData?.condominium?.name ?? '',
      },
      {
        title: t('contracts.list.condominium_fiscal_code', 'Codice fiscale'),
        className: 'd-lg-block flex-fill',
        style: { width: '8%' },
        sortable: false,
        data: ({ rowData }) => rowData?.condominium?.fiscal_code ?? '',
      },
      {
        title: t('contracts.list.service_name', 'Servizio'),
        className: 'd-lg-block flex-fill',
        style: { width: '8%' },
        sortable: false,
        data: this.renderService,
      },
      {
        title: t('contracts.list.supplier_name', 'Fornitore'),
        className: 'd-lg-block flex-fill',
        style: { width: '6%' },
        sortable: false,
        data: this.renderSupplier,
      },
      {
        title: t('contracts.list.expire_at', 'Data scadenza'),
        className: 'd-lg-block flex-fill',
        style: { width: '8%' },
        sortable: true,
        sortingKey: 'expire_at',
        data: ({ rowData }) => {
          return renderExpireAt(rowData.expire_at)
        },
      },
      {
        title: t('contracts.list.has_documents', 'Documenti'),
        className: 'd-lg-block flex-fill text-truncate',
        style: { width: '8%' },
        data: this.hasAttachmentsRenderer,
      },
      {
        title: t('contracts.list.dropzone', 'Dropzone'),
        className: 'd-lg-block flex-fill',
        style: { width: '8%' },
        sortable: false,
        data: ({ rowData }) => (
          <ColumnDropzone
            id={rowData.id}
            onFinishDrop={() => {
              this.handleSearch()
            }}
          />
        ),
      },
    ]
  }

  getData = async (index = 0, chunkSize, sortData) => {
    await this.props.dispatch(getContracts({ offset: index, limit: chunkSize, sortData, filter: this.state.filter }))
  }
  getDataExpired = async (index = 0, chunkSize, sortData) => {
    await this.props.dispatch(
      getContracts({ offset: index, limit: chunkSize, sortData, filter: this.state.filter, expired: true })
    )
  }

  onFilterChange = filter => {
    this.setState({ filter }, () => {
      if (filter === '') {
        this.handleSearch()
      }
    })
  }

  rowClasses = ({ expire_at }) => {
    let days = 5
    let nowDate = new Date()
    let expireDate = new Date(expire_at)
    let limitDate = new Date(expireDate - days * 24 * 60 * 60 * 1000)
    if (expire_at) {
      if (nowDate > expireDate) {
        return 'bg-expired'
      }
      if (nowDate >= limitDate && nowDate < expireDate) {
        return 'bg-warning'
      }
      if (nowDate < limitDate) {
        return ''
      }
    } else return ''
  }
  onFilterServices = services => {
    this.props.dispatch(filterServices(services))
  }
  onFilterCondominia = condominium => {
    if (condominium) this.props.dispatch(filterCondominia([condominium.id]))
    else {
      this.props.dispatch(filterCondominia([]))
      this.handleSearch()
    }
  }
  onFilterUsers = async user => {
    if (user) this.props.dispatch(filterUsers([user.id]))
    else {
      this.props.dispatch(filterUsers([]))
      this.handleSearch()
    }
  }
  onChangeDatesFilter = dates => {
    this.props.dispatch(filterDates(dates))
  }

  onCloseContractModal = () => this.setState({ show_modal_contract: false, selected_contract: '' })
  onCloseDocumentModal = () => this.setState({ show_modal_upload: false, selected_contract: '' })

  deleteContract = async ({ id, condominium_id, service }) => {
    let { services: tServices } = this.props.trans?.tables ?? {}
    showConfirmDialog(
      <Trans i18nKey="contracts.delete_contract_modal.modal_title">Elimina contratto</Trans>,
      <Trans
        i18nKey="contracts.delete_contract_modal.modal_msg"
        values={{ contract_name: tServices?.[service.codename] ?? service.codename }}
        defaults="Per procedere alla cancellazione del contratto relativo al servizio di <1>{{ contract_name }}</1> clicca su Elimina"
        components={{ 1: <b /> }}
      />,
      async () => {
        await this.props.dispatch(deleteContract({ contract_id: id, condominium_id }))
        this.handleSearch()
      },
      () => {},
      'primary',
      <Trans i18nKey="common.delete_button">Elimina</Trans>
    )
  }

  handleSelectTypeContract = value => () => this.setState({ activeTab: value, selected_contracts: [] })
  handleSearch = () => {
    let { activeTab } = this.state
    if (activeTab === 'attivi') this.tableRef?.current?.refreshData()
    if (activeTab === 'scaduti') this.tableRefExpired?.current?.refreshData()
  }

  loadUsersOptions = async inputValue => {
    let url = `${
      config.SERVER_API_URL
    }/v1/users?fields[tax_data][]=business_name&fields[users][]=tax_datum&include=tax_datum&filter[q]=${encodeURIComponent(
      inputValue
    )}&filter[with_role]=${getRoleIdByName('building_administrator', this.props.roles)}`
    let response = await axios({ url, method: 'get' })
    let users_data = jsonapiToData(response.data)
    let usersOpts = users_data.data.map(c => ({
      id: c.id,
      value: c.id,
      label: c.tax_datum.business_name,
    }))
    this.setState({ usersOpts })
    return usersOpts
  }
  loadCondominiaOptions = async inputValue => {
    let url = `${config.SERVER_API_URL}/v1/condominia?fields[condominia][]=name&filter[q]=${encodeURIComponent(
      inputValue
    )}`
    let response = await axios({ url, method: 'get' })
    let condominia_data = jsonapiToData(response.data)
    let condominiaOpts = condominia_data.data.map(c => ({
      id: c.id,
      value: c.id,
      label: c.name,
    }))
    this.setState({ condominiaOpts })
    return condominiaOpts
  }

  render() {
    let { activeTab, type_selected, selected_contracts, loading, progress, usersOpts, condominiaOpts } = this.state
    let {
      contracts,
      expired_contracts,
      expired_total,
      total,
      services,
      filtered_services,
      filtered_condominia,
      filtered_users,
      trans,
      // filters,
      suppliers,
    } = this.props
    let { services: tServices } = trans?.tables ?? {}
    if (!contracts || this.state.ready === false) return null
    let servicesOptions = services
      .map(v => ({ ...v, id: v.value, name: tServices?.[v.codename] ?? v.label }))
      .sort((a, b) => (a.name < b.name ? -1 : 1))
      .map(v => ({
        id: v.id,
        name: (
          <div className="d-flex">
            <div className="text-center pe-1">
              <i className={`${v.icon_classes}`} style={{ width: '1.6rem' }} />
            </div>
            <div>{tServices?.[v.codename] ?? v.label}</div>
          </div>
        ),
      }))

    let columns = this.getColumn()

    return (
      <>
        <div className="container-fluid flex-fill d-flex flex-column px-0 ps-lg-3 pe-lg-3 pt-2 zi-1">
          <div className="d-flex flex-column flex-fill">
            <div className="container-fluid px-0">
              <div className="d-flex flex-fill">
                <div className="d-flex flex-fill flex-column flex-wrap">
                  <div className="d-flex flex-wrap gap-2 mb-2">
                    <div className="ms-xxl-auto" style={{ maxWidth: '13rem' }}>
                      <CheckboxFilter
                        label={t('contracts.filtered_services', 'Servizi')}
                        items={servicesOptions}
                        filteredItems={filtered_services}
                        onChangeFilter={this.onFilterServices}
                      />
                    </div>
                    <div style={{ display: 'flex' }}>
                      <DatesFilter onSubmit={this.onChangeDatesFilter} />
                    </div>
                    <div style={{ width: '14rem' }}>
                      <StyledSelect
                        className="filter-condominium"
                        styles={{ width: '14rem' }}
                        isClearable
                        placeholder={'Filtra per condominio'}
                        value={condominiaOpts.find(o => parseInt(o.id) === parseInt(filtered_condominia[0]))}
                        onChange={this.onFilterCondominia}
                        options={condominiaOpts}
                        loadOptions={this.loadCondominiaOptions}
                      />
                    </div>
                    <WithCapabilities isAdmin={true}>
                      <div style={{ width: '16rem' }}>
                        <StyledSelect
                          className="filter-condominium"
                          styles={{ minWidth: '16rem', width: '14rem' }}
                          isClearable
                          placeholder={'Filtra per amministratore'}
                          value={usersOpts.find(o => parseInt(o.id) === parseInt(filtered_users[0]))}
                          onChange={this.onFilterUsers}
                          options={usersOpts}
                          loadOptions={this.loadUsersOptions}
                        />
                      </div>
                    </WithCapabilities>
                    <div style={{ width: '14rem' }}>
                      <SearchBox
                        value={this.state.filter}
                        onChange={this.onFilterChange}
                        disabled={this.props.show_form}
                        onEnter={this.handleSearch}
                      />
                    </div>
                    <Button onClick={this.handleSearch} variant="secondary">
                      <i className="fa fa-search me-2" />
                      <Trans i18nKey="common.cancel_button">Cerca</Trans>
                    </Button>
                  </div>
                  <div className="d-flex align-items-stretch">
                    <WithCapabilities isAdmin={true}>
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tooltip-multiselect`}>
                            <Trans i18nKey="contracts.enable_multiselect_label">Abilita/Disabilita Selezione</Trans>
                          </Tooltip>
                        }>
                        <Button
                          onClick={this.handleSelection}
                          className="rounded-0 no-shadow-button"
                          variant="secondary">
                          <i className="fa-solid fa-list-check me-1"></i>
                        </Button>
                      </OverlayTrigger>
                    </WithCapabilities>
                    {type_selected === 'multiple' && (
                      <Dropdown className="me-2 action-menu-dropdown">
                        <Dropdown.Toggle
                          variant="light"
                          id="multi-selected-menu"
                          className="rounded-0 action-menu-dropdown">
                          <Trans i18nKey="contracts.bulk_action_label">Azioni massive</Trans>
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          {Object.keys(this.bulk_actions).map(a => {
                            return (
                              <Dropdown.Item
                                key={a}
                                disabled={selected_contracts.length === 0}
                                onClick={this.handleToReviewSelected(a)}>
                                {this.bulk_actions[a]}
                              </Dropdown.Item>
                            )
                          })}
                        </Dropdown.Menu>
                      </Dropdown>
                    )}
                    <button
                      onClick={this.handleSelectTypeContract('attivi')}
                      className={`btn btn-outline-primary btn-tab ${activeTab === 'attivi' ? 'btn-tab-active' : ''}`}>
                      Contratti attivi
                    </button>
                    <button
                      onClick={this.handleSelectTypeContract('scaduti')}
                      className={`btn btn-outline-primary btn-tab ${activeTab === 'scaduti' ? 'btn-tab-active' : ''}`}>
                      Contratti scaduti
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {activeTab === 'attivi' && (
              <div className={`d-flex ${activeTab !== 'attivi' ? 'd-none' : ''}`} style={{ minHeight: '80vh' }}>
                <ResponsiveTable
                  ref={this.tableRef}
                  className="flex-fill border"
                  rowClasses={this.rowClasses}
                  headerClasses="bg-secondary"
                  menuWidth="70rem"
                  data={contracts}
                  getData={this.getData}
                  totalRecords={total}
                  columns={columns}
                  filter={this.state.filter}
                />
              </div>
            )}
            {activeTab === 'scaduti' && (
              <div className={`d-flex ${activeTab !== 'scaduti' ? 'd-none' : ''}`} style={{ minHeight: '80vh' }}>
                <ExpiredList
                  tableRef={this.tableRefExpired}
                  className="flex-fill border"
                  headerClasses="bg-secondary"
                  expiredContracts={expired_contracts}
                  roles={this.props.roles}
                  services={this.props.services}
                  getData={this.getDataExpired}
                  totalRecords={expired_total}
                  columns={columns}
                />
              </div>
            )}
          </div>
        </div>
        {loading === true && (
          <div
            className="d-flex w-100 vh-94 justify-content-center"
            style={{ position: 'absolute', backgroundColor: '#cfcfcf90', zIndex: '99999' }}>
            <div className="w-100 m-auto text-center">
              <div className="loader-box">
                <Spinner animation="border" role="status"></Spinner>
                <div>Loading...</div>
                <div className="m-auto" style={{ width: '10rem' }}>
                  <ProgressBar now={progress} label={`${progress}%`} />
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.show_modal_contract && (
          <ModalContract
            show_modal={this.state.show_modal_contract}
            contract={this.state.selected_contract}
            onCloseModal={this.onCloseContractModal}
            tServices={tServices}
            suppliers={suppliers}
          />
        )}
        {this.state.show_modal_upload && (
          <ModalUploadDocument
            show_modal={this.state.show_modal_upload}
            contract={this.state.selected_contract}
            onCloseModal={this.onCloseDocumentModal}
            refreshAttachments={this.refreshAttachments}
            deleteAttachment={this.deleteAttachment}
          />
        )}
      </>
    )
  }
}

export default connect(mstp)(ContractsList)
