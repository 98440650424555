import React from 'react'
import { Button, Modal, Table } from 'react-bootstrap'
import i18next from 'i18next'
import { Trans } from 'react-i18next'
import ShowMunicipality from 'components/system_wide/show_municipality'
import { renderExpireAt } from 'components/system_wide/utils'
import ModalContract from 'components/contracts/modal-contract'

const { t } = i18next

const initialState = {
  id: null,
  name: '',
  surname: '',
  email: '',
  role_id: '',
  tax_datum_attributes: {
    email: '',
    business_name: '',
    vat_number: '',
    street_address: '',
    cap: '',
    city: '',
    region: '',
    province: '',
    phone: '',
    mobile_phone: '',
    sdi: '',
    iban: '',
    country_id: 1,
  },
}

class ShowUserModal extends React.Component {
  static defaultProps = {
    show: false,
    onCloseForm: () => {},
    user: initialState,
    contracts: [],
  }
  state = {
    show_modal_contract: false,
    contract: {},
  }

  createContract = ct => {
    let contract = { ...ct }
    contract.user = this.props.user
    contract.condominium = { name: 'Nessun condominio associato', street: '' }
    return contract
  }

  goToContract = ct => () => {
    let contract = this.createContract(ct)
    this.setState({ show_modal_contract: true, contract })
    this.onCloseForm()
  }
  onCloseContractModal = () => this.setState({ show_modal_contract: false })

  renderContract = () => {
    let { contracts } = this.props

    return (
      <Table striped bordered hover size="sm">
        <thead>
          <tr>
            <th>Servizio</th>
            <th>Scadenza</th>
            <th className="text-center">Visualizza</th>
          </tr>
        </thead>
        <tbody>
          {contracts.map(ct => {
            return (
              <tr style={{ height: '1rem' }} key={`ct-${ct.id}`}>
                <td>
                  <i className={`p-2 ${ct.service.icon_classes}`} />
                  {ct.service.description}
                </td>
                <td className="p-2">{renderExpireAt(ct.expire_at)}</td>
                <td className="text-center">
                  <Button className="ms-2 btn-sm btn-secondary" onClick={this.goToContract(ct)}>
                    Visualizza
                  </Button>
                </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    )
  }

  render() {
    let { user, show, roles, tRoles, trans, show_contract } = this.props
    let { name, surname, email, role_id, tax_datum_attributes = {} } = user
    let {
      street_address = '',
      phone = '',
      cap,
      city,
      vat_number = '',
      business_name = '',
      mobile_phone = '',
      sdi = '',
      iban = '',
      province,
      region,
    } = tax_datum_attributes
    let { services: tServices } = trans?.tables ?? {}

    return (
      <>
        <Modal
          backdrop="static"
          centered
          show={show && !this.state.show_modal_contract}
          onHide={this.props.onCloseModal}
          size="xl">
          <Modal.Header className="pb-0" closeButton>
            <Modal.Title>
              <h4 className="text-primary p-1">
                <Trans i18nKey="'users.show_user.modal_title'">Visualizza utente</Trans>
              </h4>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="d-flex flex-column">
            <div className="row">
              <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.name">Nome</Trans>
                </div>
                <div className="text-break">{name}</div>
              </div>
              <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.surname">Cognome</Trans>
                </div>
                <div className="text-break">{surname}</div>
              </div>
              <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.email">Email</Trans>
                </div>
                <div className="text-wrap-ow">{email}</div>
              </div>
            </div>
            <div className="row pt-3 border-top">
              <div className="mb-2 col-lg-6 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.business_name">Ragione sociale</Trans>
                </div>
                <div className="text-wrap-ow">{business_name}</div>
              </div>
              <div className="mb-3 col-lg-6 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.vat_number">P.I.</Trans>
                </div>
                <div>{vat_number}</div>
              </div>
              <div className="mb-3 col-lg-6 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.sdi">SDI</Trans>
                </div>
                <div>{sdi}</div>
              </div>
              <div className="mb-3 col-lg-6 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.iban">IBAN</Trans>
                </div>
                <div>{iban}</div>
              </div>
            </div>
            <div className="row pt-3 border-top">
              <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.street_address">Indirizzo</Trans>
                </div>
                <div className="text-wrap-ow">{street_address}</div>
              </div>
              <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.tel">Telefono</Trans>
                </div>
                <div>{phone}</div>
              </div>
              <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.mobile_phone">Cellulare</Trans>
                </div>
                <div>{mobile_phone}</div>
              </div>
            </div>
            <ShowMunicipality region={region} province={province} city={city?.id} cap={cap} />
            <div className="row">
              <div className="mb-3 col-lg-4 col-md-6 col-sm-12">
                <div className="text-muted modal-title">
                  <Trans i18nKey="users.user_form.role">Ruolo</Trans>
                </div>
                <div>{tRoles?.[roles?.[role_id]] ?? t('user.user_form.not_role', 'Nessun ruolo')}</div>
              </div>
            </div>
            {show_contract !== false && (
              <>
                <div className="row border-top mt-3 pt-2">
                  <h5>Contratti</h5>
                </div>
                <div>{this.renderContract()}</div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.props.onCloseModal} variant="secondary">
              <Trans i18nKey="common.close_button">Chiudi</Trans>
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.show_modal_contract && (
          <ModalContract
            show_modal={this.state.show_modal_contract}
            contract={this.state.contract}
            onCloseModal={this.onCloseContractModal}
            tServices={tServices}
          />
        )}
      </>
    )
  }
}

export default ShowUserModal
